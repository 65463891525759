import { LMS_URL, SESSION_URL } from "../config";
const initialState = {
  data: {},
  error: null,
  isLoading: false,
  success: null,
};

const userPlans = (state = initialState, action) => {
  switch (action.type) {
    case "plan/pending":
      return { ...state, isLoading: true, error: null };
    case "plan/fulfilled":
      return { ...state, isLoading: false, data: action?.payload, error: null };
    case "plan/rejected":
      return { ...state, isLoading: false, error: action.error };
    case "planUpdate/pending":
      return { ...state, isLoading: true, error: null };
    case "planUpdate/fulfilled":
      return {
        ...state,
        isLoading: false,
        data: { ...state.data, ...action.payload },
        error: null,
      };
    case "planUpdate/rejected":
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

export const getUserPlan = () => async (dispatch) => {
  try {
    dispatch({ type: "plan/pending" });
    const response = await fetch(`${SESSION_URL}/backend-node/get_kfp_goal`, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: localStorage.getItem("accessToken"),
      },
    });
    const json = await response.json();
    if (json.ok) {
      const data = json?.data?.length === 1 ? json?.data[0] : {};
      dispatch({ type: "plan/fulfilled", payload: data });
    } else {
      dispatch({ type: "plan/rejected", error: json?.error });
    }
    return json?.data;
  } catch (e) {
    dispatch({ type: "plan/rejected", error: e });
  }
};
export const updateUserPlan = (data) => async (dispatch) => {
  try {
    dispatch({ type: "planUpdate/pending" });
    const mergeData = { type: "planAccounts", ...data };
    const response = await fetch(`${SESSION_URL}/backend-node/set_kfp_goal`, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(mergeData),
    });
    const json = await response.json();
    if (json.ok) {
      dispatch({ type: "planUpdate/fulfilled", payload: data });
    } else {
      dispatch({ type: "planUpdate/rejected", error: json?.error });
    }
  } catch (e) {
    dispatch({ type: "planUpdate/rejected", error: e });
  }
};
export const sendUserPlanLMS = (data) => async (dispatch) => {
  try {
    const response = await fetch(`${LMS_URL}/admin/kfp_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    });
    const json = await response.json();
    return json;
  } catch (e) {
    return e;
  }
};
export default userPlans;
