import { addToast } from "../fetchWrapper/toast";
import { getHook, putHook } from "../fetchWrapper/useAxios";
import { getUserData } from "./userData";

const initialState = {
  data: {},
  error: null,
  loading: false,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case "profile/expert/pending":
      return { ...state, loading: true, error: null };
    case "profile/expert/fulfilled":
      return { ...state, loading: false, expert: action.payload };
    case "profile/expert/rejected":
      return { ...state, error: action.error };

    case "profile/update/pending":
      return { ...state, loading: true, success: null };
    case "profile/update/fulfilled":
      return {
        ...state,
        loading: false,
        expert: action.payload,
        success: true,
      };

    default:
      return state;
  }
};

export const getExpertProfile = () => async (dispatch) => {
  dispatch({ type: "profile/expert/pending" });
  const response = await getHook(dispatch, `/expert/profile`);
  if (response?.detail) {
    dispatch({ type: "profile/expert/rejected", error: response?.detail });
  } else {
    dispatch({ type: "profile/expert/fulfilled", payload: response });
  }
};

export const updateDataExpert = (data) => async (dispatch) => {
  dispatch({ type: "profile/update/pending" });
  const response = await putHook(dispatch, `/expert/profile`, data);
  if (response?.detail) {
    dispatch({ type: "profile/expert/rejected", error: response?.detail });
  } else {
    dispatch(getUserData());
    dispatch({ type: "profile/update/fulfilled", payload: response });
    dispatch(addToast({ text: "Данные сохранены", type: "success" }));
  }
};

export default profile;
