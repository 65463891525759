import { PDF_GENERATE_URL } from "../config";
import { addToast } from "../fetchWrapper/toast";
import {
  getHook,
  pdfMakeGetHook,
  pdfMakePostHook,
  postHook,
} from "../fetchWrapper/useAxios";

const initialState = {
  data: {},
  user: {},
  error: null,
  loading: false,
  goals: [],
  finPlan: {},
  importPortfiles: [],
};

const portfolio = (state = initialState, action) => {
  switch (action.type) {
    case "portfolio/load/pending":
      return { ...state, loading: true, error: null, success: null };
    case "portfolio/load/fulfilled":
      return {
        ...state,
        loading: false,
        goals: action.payload,
        user: action.payload?.user,
      };
    case "portfolio/load/rejected":
      return { ...state, error: action.error };
    case "portfolioGoal/load/pending":
      return { ...state, loading: true, error: null, success: null };
    case "portfolioGoal/load/fulfilled":
      return {
        ...state,
        loading: false,
        goals: action.payload,
      };
    case "portfolioGoal/load/rejected":
      return { ...state, error: action.error };

    case "portfile/load/pending":
      return { ...state, loading: true, error: null, success: null };
    case "portfile/load/fulfilled":
      return {
        ...state,
        loading: false,
        data: action.payload,
        user: action.payload?.user,
      };
    case "portfolio/get/rejected":
      return { ...state, error: action.error };

    case "portfolio/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "portfolio/get/fulfilled":
      return {
        ...state,
        loading: false,
        importPortfiles: action.payload,
      };
    case "finPlan/load/rejected":
      return { ...state, error: action.error };

    case "finPlan/load/pending":
      return { ...state, loading: true, error: null, success: null };
    case "finPlan/load/fulfilled":
      return {
        ...state,
        loading: false,
        finPlan: action.payload,
      };
    case "portfile/load/rejected":
      return { ...state, error: action.error };

    case "portfolio/create/pending":
      return { ...state, loading: true, error: null, success: null };
    case "portfolio/create/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "portfolio/create/rejected":
      return { ...state, error: action.error };

    case "portfolio/check/pending":
      return { ...state, loading: true, error: null, success: null };
    case "portfolio/check/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "portfolio/check/rejected":
      return { ...state, error: action.error };

    case "portfolio/send/pending":
      return { ...state, loading: true, error: null, success: null };
    case "portfolio/send/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "portfolio/send/rejected":
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export const getPortfolioStudent =
  (portfile_id, user_id) => async (dispatch) => {
    dispatch({ type: "portfolioGoal/load/pending" });
    const response = await getHook(
      dispatch,
      `/expert/get_student_goals?briefcase_id=${portfile_id}&user_id=${user_id}`,
    );
    if (response?.detail) {
      dispatch({
        type: "portfolioGoal/load/rejected",
        error: response?.detail,
      });
    } else {
      dispatch({ type: "portfolioGoal/load/fulfilled", payload: response });
    }
  };
export const returnStudentPortfolio = (data, user_id) => async (dispatch) => {
  const response = await postHook(dispatch, "/expert/return_briefcase", data);
  if (!response?.detail) {
    dispatch(
      addToast({ text: "Портфель возвращен на доработку", type: "success" }),
    );
  }
};

export const getAllPortfiles = () => async (dispatch) => {
  dispatch({ type: "portfolio/get/pending" });
  const response = await getHook(dispatch, `/portfile/imported_briefcases`);
  if (response?.detail) {
    dispatch({ type: "portfolio/get/rejected", error: response?.detail });
  } else {
    dispatch({ type: "portfolio/get/fulfilled", payload: response });
  }
  return response;
};
export const getExportedPortfile = (id) => async (dispatch) => {
  dispatch({ type: "portfolio/get/pending" });
  const response = await getHook(
    dispatch,
    `/portfile/exported_briefcase?briefcase_id=${id}`,
  );
  if (response?.detail) {
    dispatch({ type: "portfolio/get/rejected", error: response?.detail });
  } else {
    dispatch({ type: "portfolio/get/fulfilled", payload: response });
  }
  return response;
};

export const exportPortifle = (goalId, stepId) => async (dispatch) => {
  const response = await postHook(
    dispatch,
    `/portfile/export_briefcase?goal_id=${goalId}&step_id=${stepId}`,
  );
  return response;
};

export const readyPdf =
  (portfile_id, user_id, email, uuid) => async (dispatch) => {
    dispatch({ type: "portfolio/load/pending" });
    const response = await pdfMakePostHook(dispatch, `/task`, {
      id: uuid,
      url: `${PDF_GENERATE_URL}/kfp/ready/${portfile_id}`,
      user_id: user_id,
      name: email,
      type: "kfp",
    });
    if (response?.detail) {
      dispatch({ type: "portfolio/load/rejected", error: response?.detail });
    } else {
      dispatch({ type: "portfolio/load/fulfilled", payload: response });
    }
  };
export const sentPdf =
  (portfile_id, user_id, email, uuid) => async (dispatch) => {
    dispatch({ type: "portfolio/load/pending" });
    const response = await pdfMakePostHook(dispatch, `/task`, {
      id: uuid,
      url: `${PDF_GENERATE_URL}/kfp/sent/${portfile_id}`,
      user_id: user_id,
      name: email,
      type: "kfp",
    });
    if (response?.detail) {
      dispatch({ type: "portfolio/load/rejected", error: response?.detail });
    } else {
      dispatch({ type: "portfolio/load/fulfilled", payload: response });
    }
    return response;
  };
export const getPdf = (taskId) => async (dispatch) => {
  dispatch({ type: "portfolio/load/pending" });
  const response = await pdfMakeGetHook(
    dispatch,
    `files/pdfcheck?task_id=${taskId}`,
  );
  if (response?.detail) {
    dispatch({ type: "portfolio/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "portfolio/load/fulfilled", payload: response });
  }
  return response;
};
export const getPortfileStudent =
  (portfile_id, user_id) => async (dispatch) => {
    dispatch({ type: "portfile/load/pending" });
    const response = await getHook(
      dispatch,
      `/expert/get_student_briefcase?briefcase_id=${portfile_id}&user_id=${user_id}`,
    );
    if (response?.detail) {
      dispatch({ type: "portfile/load/rejected", error: response?.detail });
    } else {
      dispatch({ type: "portfile/load/fulfilled", payload: response });
    }
  };

export const getFinPlanStudent = (goal_id) => async (dispatch) => {
  dispatch({ type: "finPlan/load/pending" });
  const response = await getHook(
    dispatch,
    `/expert/get_student_financial_plan?goal_id=${goal_id}`,
  );
  if (response?.detail) {
    dispatch({ type: "finPlan/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "finPlan/load/fulfilled", payload: response });
  }
};

export const createPortfolio = (data) => async (dispatch) => {
  dispatch({ type: "portfolio/create/pending" });
  const response = await postHook(dispatch, `/portfile/create_portfile`, data);
  if (response?.detail) {
    dispatch({ type: "portfolio/create/rejected", error: response?.detail });
  } else {
    dispatch({ type: "portfolio/create/fulfilled", payload: response });
  }
};

export const startPortfolioChecking = (id) => async (dispatch) => {
  dispatch({ type: "portfolio/check/pending" });
  const response = await postHook(dispatch, `/expert/check_student_portfile`, {
    id,
  });
  if (response?.detail) {
    dispatch({ type: "portfolio/check/rejected", error: response?.detail });
  } else {
    dispatch({ type: "portfolio/check/fulfilled", payload: response });
  }
};

export const portfolioCheckSend = (id) => async (dispatch) => {
  dispatch({ type: "portfolio/send/pending" });
  const response = await postHook(dispatch, `/expert/send`, {
    id,
  });
  if (response?.detail) {
    dispatch({ type: "portfolio/send/rejected", error: response?.detail });
  } else {
    dispatch({ type: "portfolio/send/fulfilled", payload: response });
  }
};

export const portfolioCheckEnd = (id) => async (dispatch) => {
  dispatch({ type: "portfolio/send/pending" });
  const response = await postHook(dispatch, `/expert/checking_end`, {
    id,
  });
  if (response?.detail) {
    dispatch({ type: "portfolio/send/rejected", error: response?.detail });
  } else {
    dispatch({ type: "portfolio/send/fulfilled", payload: response });
  }
};

export const getCheckPortfolio = (portfile_id, expert) => async (dispatch) => {
  dispatch({ type: "portfolio/load/pending" });
  const response = await getHook(
    dispatch,
    `/report/?portfile_id=${portfile_id}&expert=${expert}`,
  );
  if (response?.detail) {
    dispatch({ type: "portfolio/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "portfolio/load/fulfilled", payload: response });
  }
};
export const getReport = (portfile_id, expert) => async (dispatch) => {
  dispatch({ type: "portfolio/load/pending" });
  const response = await getHook(
    dispatch,
    `/report?portfile_id=${portfile_id}&expert=${expert}`,
  );
  if (response?.detail) {
    dispatch({ type: "portfolio/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "portfolio/load/fulfilled", payload: response });
  }
};

export const downloadPdfReport = (briefcase_id) => async (dispatch) => {
  dispatch({ type: "portfolio/load/pending" });
  const response = await getHook(
    dispatch,
    `/report/generate_pdf?briefcase_id=${briefcase_id}`,
  );
  if (response?.detail) {
    dispatch({ type: "portfolio/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "portfolio/load/fulfilled", payload: response });
  }
  return response;
};

export const autoCheckReset = (briefcase_id) => async (dispatch) => {
  dispatch({ type: "portfolio/load/pending" });
  const response = await postHook(
    dispatch,
    "/expert/reset_autocheck_briefcase",
    { briefcase_id: briefcase_id },
  );
  if (response?.detail) {
    dispatch({ type: "portfolio/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "portfolio/load/fulfilled", payload: response });
    dispatch(addToast({ text: "Автопроверка сброшена", type: "success" }));
  }
  return response;
};

export default portfolio;
