import axios from "axios";
import { memoizedRefreshToken } from "./refreshToken";
import { API_URL, SESSION_URL } from "../config";

axios.defaults.baseURL = API_URL;

axios.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("accessToken");
    // console.log(`accessToken ---- ${accessToken}`);
    if (accessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${accessToken}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;
    if (
      error?.response?.status === 403 &&
      error?.response?.data?.detail?.isProfinansy &&
      !config?.sent
    ) {
      config.sent = true;
      const result = await fetch(`${SESSION_URL}/auth/session?type=web`, {
        credentials: "include",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const json = await result.json();
      if (json?.token) {
        localStorage.setItem("accessToken", json.token);
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${json?.token}`,
          "Content-type": "application/json",
        };
      }
      return axios(config);
    } else if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;
      const result = await memoizedRefreshToken();
      // console.log(`result ---- ${result}`);
      if (result?.accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.accessToken}`,
          "Content-type": "application/json",
        };
      }
      return axios(config);
    }
    return Promise.reject(error);
  },
);

export const axiosPrivate = axios;
