import { addToast } from "../fetchWrapper/toast";
import {
  deleteHook,
  getHook,
  imageDownloadHook,
  postHook,
  putHook,
} from "../fetchWrapper/useAxios";

const initialState = {
  data: [],
  assetsData: [],
  tacticsData: [],
  finalData: [],
  portfile_data: [],
  contracts: ["Расчётный", "Поставочный"],
  deals: ["Лонг", "Шорт"],
  options: ["Call-опцион", "Put-опцион"],
  base_assets_futures: [
    "Индексы",
    "Акции",
    "Процентные ставки",
    "Валютные пары",
    "Корзина ОФЗ или еврооблигаций",
    "Металлы",
    "Энергоносители",
    "Агропродукция",
    "Мягкие товары",
  ],
  base_assets: [
    "Акции",
    "Облигации",
    "Индексы",
    "Фьючерсы",
    "Нефть и Энергоносители",
    "Золото и Металлы",
    "Агропродукция",
    "Процентные ставки",
    "Имущественные права",
  ],
  etf_base_assets: [
    "Акции",
    "Облигации",
    "Криптовалюта",
    "Опционы",
    "Фьючерсы",
    "Металлы",
    "Смешанные активы",
  ],
  rents: [
    "Посуточно",
    "Помесячно",
    "Поквартально",
    "Раз в полгода",
    "Раз в год",
    "С последующей продажей",
  ],
  error: null,
  loading: false,
  success: false,
  message: "",
};

const assets = (state = initialState, action) => {
  let data = action?.payload;
  switch (action.type) {
    case "assets/load/pending":
      return { ...state, loading: true, error: null };
    case "assets/load/fulfilled":
      return { ...state, loading: false, data: action?.payload, success: true };
    case "assets/load/rejected":
      return { ...state, error: action.error };

    case "assets/create/pending":
      return { ...state, loading: true, error: null, success: null };
    case "assets/create/fulfilled":
      return {
        ...state,
        loading: false,
        data: action?.payload,
        success: true,
        message: action?.payload?.message,
      };
    case "assets/create/rejected":
      return { ...state, error: action.error };

    case "assets/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "assets/get/fulfilled":
      return {
        ...state,
        loading: false,
        success: true,
        assetsData: data,
      };
    case "assets/get/rejected":
      return { ...state, error: action.error };
    case "final_assets/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "final_assets/get/fulfilled":
      return {
        ...state,
        loading: false,
        finalData: data,
        ...data,
        success: true,
      };
    case "final_assets/get/rejected":
      return { ...state, error: action.error };

    case "tacticAssets/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "tacticAssets/get/fulfilled":
      return {
        ...state,
        loading: false,
        success: true,
        tacticsData: data,
      };
    case "tacticAssets/get/rejected":
      return { ...state, error: action.error };

    case "assets/update/pending":
      return { ...state, loading: true, error: null };
    case "assets/update/fulfilled":
      return { ...state, loading: false, success: true };
    case "assets/update/rejected":
      return { ...state, error: action.error };

    case "assets/delete/pending":
      return { ...state, loading: true, error: null };
    case "assets/delete/fulfilled":
      return { ...state, loading: false, success: true };
    case "assets/delete/rejected":
      return { ...state, error: action.error };

    case "assets/transfer/pending":
      return { ...state, loading: true, error: null, success: null };
    case "assets/transfer/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "assets/transfer/rejected":
      return { ...state, error: action.error };

    case "charts/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "charts/get/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "charts/get/portfile/fulfilled":
      return {
        ...state,
        loading: false,
        portfile_data: action.payload,
        success: true,
      };
    case "charts/get/rejected":
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export const updateAsset = ({
  goal_id,
  asset_id,
  type_inctrument,
  asset_type,
  ticker,
  name,
  price,
  current_price,
  count,
  purchase_currency,
  currency_asset,
  asset_part,
  country,
  sector_economy,
  comment,
  portfile_id,
  active,
  activeChart,
  lot_size,
  matdate,
  goal_currency,
  amount_of_starting_capital,
  goal_currency_inflation,
  instrument_id,
  percentage_in_portfolio,
}) => {
  return async (dispatch) => {
    dispatch({ type: "assets/update/pending" });
    const response = await putHook(
      dispatch,
      `/portfile/assets?asset_id=${asset_id}&goal_id=${goal_id}`,
      {
        asset_type,
        ticker,
        name,
        price,
        current_price,
        count,
        purchase_currency,
        currency_asset,
        type_inctrument,
        asset_part,
        country,
        sector_economy,
        comment,
        active,
        lot_size,
        matdate,
        instrument_id,
        percentage_in_portfolio,
      },
    );

    if (response?.detail) {
      dispatch({ type: "assets/update/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/update/fulfilled", payload: response });
      dispatch(
        active
          ? getFinalAssets(goal_id, goal_currency, goal_currency_inflation)
          : getAssets(goal_id, goal_currency, amount_of_starting_capital),
      );
      dispatch(
        addToast({
          text: "Актив скорректирован",
          type: "success",
        }),
      );
    }
    return response;
  };
};

export const createAsset = (
  goal_id,
  asset_type,
  ticker,
  name,
  price,
  current_price,
  count,
  purchase_currency,
  currency_asset,
  type_inctrument,
  asset_part,
  country,
  sector_economy,
  comment,
  portfile_id,
  active,
  lot_size,
  matdate,
  goal_currency,
  amount_of_starting_capital,
  goal_currency_inflation,
  instrument_id,
  percentage_in_portfolio,
  additional,
) => {
  return async (dispatch, getState) => {
    dispatch({ type: "assets/create/pending" });
    const response = await postHook(
      dispatch,
      `/portfile/assets?goal_id=${goal_id}`,
      {
        asset_type,
        ticker,
        name,
        price,
        current_price,
        count,
        purchase_currency,
        currency_asset,
        type_inctrument,
        asset_part,
        country,
        sector_economy,
        comment,
        active,
        lot_size,
        matdate,
        instrument_id,
        percentage_in_portfolio,
        additional,
      },
    );

    if (response?.detail) {
      dispatch({ type: "assets/create/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/create/fulfilled", payload: response });
      dispatch(
        active
          ? getFinalAssets(goal_id, goal_currency, goal_currency_inflation)
          : getAssets(goal_id, goal_currency, amount_of_starting_capital),
      );
      dispatch(addToast({ text: "Актив добавлен", type: "success" }));
    }
    return response;
  };
};

export const createMethodAsset = (
  goal_id,
  data,
  goal_currency,
  amount_of_tactical_strategy,
) => {
  return async (dispatch, getState) => {
    dispatch({ type: "assets/create/pending" });
    const response = await postHook(
      dispatch,
      `/portfile/assets?goal_id=${goal_id}`,
      data,
    );

    if (response?.detail) {
      dispatch({ type: "assets/create/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/create/fulfilled", payload: response });
      dispatch(
        getTacticAssets(goal_id, goal_currency, amount_of_tactical_strategy),
      );
      dispatch(addToast({ text: "Актив добавлен", type: "success" }));
    }
    return response;
  };
};
export const updateMethodAsset = (
  goal_id,
  asset_id,
  data,
  goal_currency,
  amount_of_tactical_strategy,
) => {
  return async (dispatch, getState) => {
    dispatch({ type: "assets/create/pending" });
    const response = await await putHook(
      dispatch,
      `/portfile/assets?asset_id=${asset_id}&goal_id=${goal_id}`,
      data,
    );

    if (response?.detail) {
      dispatch({ type: "assets/create/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/create/fulfilled", payload: response });
      dispatch(
        getTacticAssets(goal_id, goal_currency, amount_of_tactical_strategy),
      );
      dispatch(addToast({ text: "Актив откорректирован", type: "success" }));
    }
    return response;
  };
};
export const deleteMethodAsset =
  (goalId, assetId, goal_currency, amount_of_tactical_strategy) =>
  async (dispatch, getState) => {
    dispatch({ type: "assets/delete/pending" });
    const response = await deleteHook(
      dispatch,
      `/portfile/assets?asset_id=${assetId}&goal_id=${goalId}`,
    );
    dispatch(
      getTacticAssets(goalId, goal_currency, amount_of_tactical_strategy),
    );
    if (response?.detail) {
      dispatch({ type: "assets/delete/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/delete/fulfilled", payload: assetId });
      dispatch(addToast({ text: "Актив удален", type: "success" }));
    }
  };

export const deleteAsset =
  (
    goalId,
    assetId,
    portfile_id,
    active,
    goal_currency,
    amount_of_starting_capital,
    goal_currency_inflation,
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: "assets/delete/pending" });
    const response = await deleteHook(
      dispatch,
      `/portfile/assets?asset_id=${assetId}&goal_id=${goalId}`,
    );
    dispatch(
      active
        ? getFinalAssets(goalId, goal_currency, goal_currency_inflation)
        : getAssets(goalId, goal_currency, amount_of_starting_capital),
    );
    if (response?.detail) {
      dispatch({ type: "assets/delete/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/delete/fulfilled", payload: assetId });

      dispatch(addToast({ text: "Актив удален", type: "success" }));
    }
  };

export const transferAsset =
  (id, active, goalId, goalCurrency, amount) => async (dispatch, getState) => {
    dispatch({ type: "assets/transfer/pending" });
    const response = await postHook(dispatch, "/portfile/assets/active", {
      id,
      active,
    });
    if (response?.detail) {
      dispatch({ type: "assets/transfer/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/transfer/fulfilled", payload: response });
      dispatch(getAssets(goalId, goalCurrency, amount));
      dispatch(
        addToast({
          text: active
            ? "Актив добавлен в итоговый портфель"
            : "Актив удален из итогового портфеля",
          type: "success",
        }),
      );
    }
  };

export const getAssets =
  (goalId, goalCurrency, amount) => async (dispatch, getState) => {
    dispatch({ type: "assets/get/pending" });
    const response = await getHook(
      dispatch,
      `/portfile/start_capital_assets?goal_id=${goalId}&goal_currency=${goalCurrency}&amount=${amount}`,
    );
    if (response?.detail) {
      dispatch({ type: "assets/get/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/get/fulfilled", payload: response });
    }
  };
export const getTacticAssets =
  (goalId, goalCurrency, amount) => async (dispatch, getState) => {
    dispatch({ type: "tacticAssets/get/pending" });
    const response = await getHook(
      dispatch,
      `/portfile/tactical_strategy_assets?goal_id=${goalId}&goal_currency=${goalCurrency}&amount=${amount}`,
    );
    if (response?.detail) {
      dispatch({ type: "tacticAssets/get/rejected", error: response?.detail });
    } else {
      dispatch({ type: "tacticAssets/get/fulfilled", payload: response });
    }
  };
export const addFile = (file) => async (dispatch) => {
  const data = new FormData();
  data.append("file", file);
  const response = await imageDownloadHook(dispatch, "/files/upload", data);
  return response;
};
export const getFinalAssets =
  (goalId, goalCurrency, amount) => async (dispatch, getState) => {
    dispatch({ type: "final_assets/get/pending" });
    const response = await getHook(
      dispatch,
      `/portfile/final_briefcase_assets?goal_id=${goalId}&goal_currency=${goalCurrency}&amount=${amount}`,
    );
    if (response?.detail) {
      dispatch({ type: "final_assets/get/rejected", error: response?.detail });
    } else {
      dispatch({ type: "final_assets/get/fulfilled", payload: response });
    }
  };
export const importFinalAssets =
  (goalId, goalCurrency, amount, data) => async (dispatch, getState) => {
    dispatch({ type: "final_assets/get/pending" });
    const response = await postHook(dispatch, `/portfile/assets/import`, data);
    dispatch(getFinalAssets(goalId, goalCurrency, amount));
    if (!response?.detail) {
      dispatch(addToast({ text: "Ядро скопировано", type: "success" }));
    }
  };

export const fetchPortfileSend = (id, text) => async (dispatch) => {
  dispatch({ type: "goal/update/pending" });
  const response = await postHook(dispatch, `/portfile/send?portfile_id=${id}`);
  if (response?.detail) {
    dispatch({ type: "goal/update/rejected", error: response?.detail });
    return false;
  } else {
    dispatch({ type: "goal/update/fulfilled", payload: response });
    dispatch(
      addToast({
        text: text
          ? text
          : response?.report_status === "Получен"
          ? 'Ваш портфель проверен. Нажмите на кнопку "Скачать отчет"'
          : "Ваш портфель отправлен на проверку.",
        type: "success",
      }),
    );
    return true;
  }
};

export const getChartsData =
  (goalId, id, expert, active, user_id) => async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: "charts/get/pending" });
    const response = await getHook(
      dispatch,
      `/chart/chart_data?goal_id=${goalId}&briefcase_id=${id}&active=${active}&expert=${false}${
        state.userData?.user?.role !== "STUDENT" ? `&user_id=${user_id}` : ""
      }`,
    );
    if (response?.detail) {
      dispatch({ type: "charts/get/rejected", error: response?.detail });
    } else {
      active
        ? dispatch({
            type: "charts/get/portfile/fulfilled",
            payload: response,
          })
        : dispatch({ type: "charts/get/fulfilled", payload: response });
    }
  };

export default assets;
