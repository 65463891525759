import { getHook } from "../fetchWrapper/useAxios";
import { getCurrencies } from "./currencies";

const initialState = {
  error: null,
  loading: false,
  success: false,
  currency: [],
  countries: [],
  risk_profile: [],
  rights: [],
  sector_economy_risk: [],
};

const dataList = (state = initialState, action) => {
  switch (action.type) {
    case "country/load/fulfilled":
      let data = action.payload;
      return { ...state, loading: false, ...data };
    case "country/load/rejected":
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
export const getDataList = () => async (dispatch, getState) => {
  const state = getState();
  const response = await getHook(dispatch, "/settings_data/list");
  if (state?.currencies?.data?.length <= 0) {
    dispatch(getCurrencies());
  }
  if (response?.detail) {
    dispatch({ type: "country/load/rejected", error: response.detail });
  } else {
    dispatch({ type: "country/load/fulfilled", payload: response });
  }
};
export default dataList;
