import { addToast } from "../fetchWrapper/toast";
import {
  deleteHook,
  getHook,
  postHook,
  putHook,
} from "../fetchWrapper/useAxios";
const initialState = {
  templates: [],
  template: {},
  assetTemplates: [],
  assetTemplate: {},
  streamSettings: [],
  activeStreamSetting: {},
  activeStream: {},
  streamArr: [],
  loading: false,
  loadingStream: false,
  loadingSettings: false,
};

const admin = (state = initialState, action) => {
  switch (action.type) {
    case "templates/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "templates/get/fulfilled":
      return {
        ...state,
        loading: false,
        templates: action.payload,
        success: true,
      };
    case "templates/get/rejected":
      return { ...state, error: action.error };

    case "template/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "template/get/fulfilled":
      return {
        ...state,
        loading: false,
        template: action.payload,
        success: true,
      };
    case "template/get/rejected":
      return { ...state, error: action.error };

    case "template/create/pending":
      return { ...state, loading: true, error: null, success: null };
    case "template/create/fulfilled":
      return {
        ...state,
        loading: false,
        template: action.payload,
        success: true,
      };
    case "template/create/rejected":
      return { ...state, error: action.error };

    case "template/put/pending":
      return { ...state, loading: true, error: null, success: null };
    case "template/put/fulfilled":
      return {
        ...state,
        loading: false,
        template: action.payload,
        success: true,
      };
    case "template/put/rejected":
      return { ...state, error: action.error };

    case "template/delete/pending":
      return { ...state, loading: true, error: null, success: null };
    case "template/delete/fulfilled":
      return {
        ...state,
        loading: false,
        template: action.payload,
        success: true,
      };
    case "template/delete/rejected":
      return { ...state, error: action.error };

    case "templates/create/pending":
      return { ...state, loading: true, error: null, success: null };
    case "templates/create/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "templates/create/rejected":
      return { ...state, error: action.error };

    case "assetTemplates/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "assetTemplates/get/fulfilled":
      return {
        ...state,
        loading: false,
        assetTemplates: action.payload,
        success: true,
      };
    case "assetTemplates/get/rejected":
      return { ...state, error: action.error };

    case "assetTemplate/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "assetTemplate/get/fulfilled":
      return {
        ...state,
        loading: false,
        assetTemplate: action.payload,
        success: true,
      };
    case "assetTemplate/get/rejected":
      return { ...state, error: action.error };

    case "assetTemplate/put/pending":
      return { ...state, loading: true, error: null, success: null };
    case "assetTemplate/put/fulfilled":
      return {
        ...state,
        loading: false,
        assetTemplate: action.payload,
        success: true,
      };
    case "assetTemplate/put/rejected":
      return { ...state, error: action.error };

    case "assetTemplate/create/pending":
      return { ...state, loading: true, error: null, success: null };
    case "assetTemplate/create/fulfilled":
      return {
        ...state,
        loading: false,
        assetTemplate: action.payload,
        success: true,
      };
    case "assetTemplate/create/rejected":
      return { ...state, error: action.error };

    case "assetTemplate/delete/pending":
      return { ...state, loading: true, error: null, success: null };
    case "assetTemplate/delete/fulfilled":
      return {
        ...state,
        loading: false,
        assetTemplate: action.payload,
        success: true,
      };
    case "assetTemplate/delete/rejected":
      return { ...state, error: action.error };

    case "streamSetting/add/pending":
      return { ...state, error: null, success: null };
    case "streamSetting/add/fulfilled":
      return {
        ...state,
        success: true,
      };
    case "streamSetting/add/rejected":
      return { ...state, error: action.error };

    case "streamSetting/get/pending":
      return { ...state, loadingSettings: true, error: null, success: null };
    case "streamSetting/get/fulfilled":
      return {
        ...state,
        loadingSettings: false,
        streamSettings: action.payload,
        success: true,
      };
    case "streamSetting/get/rejected":
      return { ...state, error: action.error };

    case "streamSettingId/get/pending":
      return { ...state, error: null, success: null };
    case "streamSettingId/get/fulfilled":
      return {
        ...state,
        activeStreamSetting: action.payload,
        success: true,
      };
    case "streamSettingId/get/rejected":
      return { ...state, error: action.error };
    case "streamById/get/pending":
      return { ...state, error: null, success: null };
    case "streamById/get/fulfilled":
      return {
        ...state,
        activeStream: action.payload,
        success: true,
      };
    case "streamById/get/rejected":
      return { ...state, error: action.error };
    case "streamGetAll/get/pending":
      return { ...state, loadingStream: true, error: null, success: null };
    case "streamGetAll/get/fulfilled":
      return {
        ...state,
        loadingStream: false,
        streamArr: action.payload,
        success: true,
      };
    case "streamGetAll/get/rejected":
      return { ...state, error: action.error };
    default:
      return state;
  }
};

export const adminGetTemplates = (params) => async (dispatch) => {
  dispatch({ type: "templates/get/pending" });
  const response = await getHook(
    dispatch,
    `/robot/goal_templates?${new URLSearchParams(params)}`,
  );
  if (response?.detail) {
    dispatch({
      type: "templates/get/rejected",
      error: response?.detail,
    });
  } else {
    dispatch({
      type: "templates/get/fulfilled",
      payload: response,
    });
  }
};
export const adminDeleteTemplates = (ids) => async (dispatch) => {
  await deleteHook(dispatch, `/robot/goal_templates`, ids);
  dispatch(
    addToast({
      text: "Выбранные шаблоны удалены",
      type: "success",
    }),
  );
  dispatch(adminGetTemplates());
};
export const adminGetTemplate = (goalId) => async (dispatch) => {
  dispatch({ type: "template/get/pending" });
  const response = await getHook(
    dispatch,
    `/robot/goal_template?goal_template_id=${goalId}`,
  );
  if (response?.detail) {
    dispatch({
      type: "template/get/rejected",
      error: response?.detail,
    });
  } else {
    dispatch({
      type: "template/get/fulfilled",
      payload: response,
    });
  }
  return response;
};
export const adminEditTemplate = (goalId, data) => async (dispatch) => {
  dispatch({ type: "template/put/pending" });
  const response = await putHook(
    dispatch,
    `/robot/goal_template?goal_template_id=${goalId}`,
    data,
  );
  if (response?.detail) {
    dispatch({
      type: "template/put/rejected",
      error: response?.detail,
    });
  } else {
    dispatch({
      type: "template/put/fulfilled",
      payload: response,
    });
    dispatch(
      addToast({
        text: "Шаблон скорректорован",
        type: "success",
      }),
    );
  }
};

export const adminCreateTemplate = (data, notify) => async (dispatch) => {
  dispatch({ type: "template/create/pending" });
  const response = await postHook(dispatch, `/robot/goal_template`, data);
  if (response?.detail) {
    dispatch({
      type: "template/create/rejected",
      error: response?.detail,
    });
  } else {
    dispatch({
      type: "template/create/fulfilled",
      payload: response,
    });
  }
  return response;
};
export const adminCopyTemplate = (data) => async (dispatch) => {
  const response = await postHook(dispatch, `/robot/copy_template_asset`, data);
  if (!response?.detail) {
    dispatch(
      addToast({
        text: "Шаблон с активами скопирован",
        type: "success",
      }),
    );
  }

  return response;
};
export const adminDeleteTemplate = (goalId) => async (dispatch) => {
  dispatch({ type: "template/delete/pending" });
  const response = await deleteHook(
    dispatch,
    `/robot/goal_template?goal_template_id=${goalId}`,
  );
  if (response?.detail) {
    dispatch({
      type: "template/delete/rejected",
      error: response?.detail,
    });
  } else {
    dispatch({
      type: "template/delete/fulfilled",
      payload: response,
    });
    dispatch(
      addToast({
        text: "Шаблон удалён",
        type: "success",
      }),
    );
  }
};
export const adminGetAssetTemplates = (goalId) => async (dispatch) => {
  dispatch({ type: "assetTemplates/get/pending" });
  const response = await getHook(
    dispatch,
    `/robot/asset_templates?goal_template_id=${goalId}`,
  );
  if (response?.detail) {
    dispatch({
      type: "assetTemplates/get/rejected",
      error: response?.detail,
    });
  } else {
    dispatch({
      type: "assetTemplates/get/fulfilled",
      payload: response,
    });
  }
};
export const adminGetAssetTemplate = (assetId) => async (dispatch) => {
  dispatch({ type: "assetTemplate/get/pending" });
  const response = await getHook(
    dispatch,
    `/robot/asset_template?asset_template_id=${assetId}`,
  );
  if (response?.detail) {
    dispatch({
      type: "assetTemplate/get/rejected",
      error: response?.detail,
    });
  } else {
    dispatch({
      type: "assetTemplate/get/fulfilled",
      payload: response,
    });
  }
};

export const adminEditAssetTemplate = (assetId, data) => async (dispatch) => {
  dispatch({ type: "assetTemplate/put/pending" });
  const response = await putHook(
    dispatch,
    `/robot/asset_template?asset_template_id=${assetId}`,
    data,
  );
  if (response?.detail) {
    dispatch({
      type: "assetTemplate/put/rejected",
      error: response?.detail,
    });
  } else {
    dispatch({
      type: "assetTemplate/put/fulfilled",
      payload: response,
    });
    dispatch(
      addToast({
        text: "Актив скорректован",
        type: "success",
      }),
    );
    dispatch(adminGetTemplate(data?.goal_template_id));
  }
};

export const adminCreateAssetTemplate = (data) => async (dispatch) => {
  dispatch({ type: "assetTemplate/create/pending" });
  const response = await postHook(dispatch, `/robot/asset_template`, data);
  if (response?.detail) {
    dispatch({
      type: "assetTemplate/create/rejected",
      error: response?.detail,
    });
  } else {
    dispatch({
      type: "assetTemplate/create/fulfilled",
      payload: response,
    });
    dispatch(
      addToast({
        text: "Актив создан",
        type: "success",
      }),
    );
    dispatch(adminGetTemplate(data?.goal_template_id));
  }
};
export const adminDeleteAssetTemplate =
  (assetId, goalId) => async (dispatch) => {
    dispatch({ type: "assetTemplate/delete/pending" });
    const response = await deleteHook(
      dispatch,
      `/robot/asset_template?asset_template_id=${assetId}`,
    );
    if (response?.detail) {
      dispatch({
        type: "assetTemplate/delete/rejected",
        error: response?.detail,
      });
    } else {
      dispatch({
        type: "assetTemplate/delete/fulfilled",
        payload: response,
      });
      dispatch(
        addToast({
          text: "Актив удалён",
          type: "success",
        }),
      );
      dispatch(adminGetTemplate(goalId));
    }
  };

export const autoCheckPortfolio = (briefcase_id) => async (dispatch) => {
  const response = await getHook(
    dispatch,
    `/robot/autocheck?briefcase_id=${briefcase_id}`,
  );
  if (response?.message) {
    dispatch(
      addToast({
        text: response?.message,
        type: "error",
      }),
    );
  } else if (response?.msg === "Успешно") {
    dispatch(
      addToast({
        text: "Автопроверка пройдена",
        type: "success",
      }),
    );
  }
  return response;
};
export const streamSettingAdd = (data) => async (dispatch) => {
  dispatch({ type: "streamSetting/add/pending" });
  const response = await postHook(dispatch, `/stream_setting/add`, data);
  if (response?.msg) {
    dispatch({ type: "streamSetting/add/reject" });
  } else {
    dispatch({ type: "streamSetting/add/fulfilled" });
    dispatch(
      addToast({
        text: "Настройка добавлена",
        type: "success",
      }),
    );
  }
  return response;
};
export const streamSettingUpdate = (data) => async (dispatch) => {
  dispatch({ type: "streamSettingUpdate/get/pending" });
  const response = await putHook(dispatch, `/stream_setting/`, data);
  if (response?.msg) {
    dispatch({ type: "streamSettingUpdate/get/reject" });
  } else {
    dispatch({ type: "streamSettingUpdate/get/fulfilled", payload: response });
    dispatch(
      addToast({
        text: "Настройка откорректирована",
        type: "success",
      }),
    );
  }
  return response;
};
export const streamSettingDelete = (id) => async (dispatch) => {
  dispatch({ type: "streamSettingDelete/get/pending" });
  const response = await deleteHook(
    dispatch,
    `/stream_setting/?stream_setting_id=${id}`,
  );
  if (response?.msg) {
    dispatch({ type: "streamSettingDelete/get/reject" });
  } else {
    dispatch({ type: "streamSettingDelete/get/fulfilled", payload: response });
    dispatch(
      addToast({
        text: "Настройка удалена",
        type: "success",
      }),
    );
  }
  return response;
};
export const streamSettingList = (params) => async (dispatch) => {
  dispatch({ type: "streamSetting/get/pending" });
  const response = await getHook(
    dispatch,
    `/stream_setting/all?${new URLSearchParams(params)}`,
  );
  if (response?.msg) {
    dispatch({ type: "streamSetting/get/reject" });
  } else {
    dispatch({ type: "streamSetting/get/fulfilled", payload: response });
  }
  return response;
};
export const streamSettingById = (params) => async (dispatch) => {
  dispatch({ type: "streamSettingId/get/pending" });
  const response = await getHook(
    dispatch,
    `/stream_setting/?${new URLSearchParams(params)}`,
  );
  if (response?.msg) {
    dispatch({ type: "streamSettingId/get/reject" });
  } else {
    dispatch({ type: "streamSettingId/get/fulfilled", payload: response });
  }
  return response;
};
export const streamById = (params) => async (dispatch) => {
  dispatch({ type: "streamById/get/pending" });
  const response = await getHook(
    dispatch,
    `/stream/?${new URLSearchParams(params)}`,
  );
  if (response?.msg) {
    dispatch({ type: "streamById/get/reject" });
  } else {
    dispatch({ type: "streamById/get/fulfilled", payload: response });
  }
  return response;
};
export const streamAdd = (data) => async (dispatch) => {
  dispatch({ type: "streamAdd/get/pending" });
  const response = await postHook(dispatch, `/stream/`, data);
  if (response?.msg) {
    dispatch({ type: "streamAdd/get/reject" });
  } else {
    dispatch({ type: "streamAdd/get/fulfilled", payload: response });
    dispatch(
      addToast({
        text: "Поток добавлен",
        type: "success",
      }),
    );
  }
  return response;
};
export const streamUpdate = (data) => async (dispatch) => {
  dispatch({ type: "streamPut/get/pending" });
  const response = await putHook(
    dispatch,
    `/stream/?stream_id=${data.id}`,
    data,
  );
  if (response?.msg) {
    dispatch({ type: "streamPut/get/reject" });
  } else {
    dispatch({ type: "streamPut/get/fulfilled", payload: response });
    dispatch(
      addToast({
        text: "Поток откорректирован",
        type: "success",
      }),
    );
  }
  return response;
};
export const streamDelete = (id) => async (dispatch) => {
  dispatch({ type: "streamDelete/get/pending" });
  const response = await deleteHook(dispatch, `/stream/?stream_id=${id}`);
  if (response?.msg) {
    dispatch({ type: "streamDelete/get/reject" });
  } else {
    dispatch({ type: "streamDelete/get/fulfilled", payload: response });
    dispatch(
      addToast({
        text: "Поток удален",
        type: "success",
      }),
    );
  }
  return response;
};
export const streamGetAll = (params) => async (dispatch) => {
  dispatch({ type: "streamGetAll/get/pending" });
  const response = await getHook(
    dispatch,
    `/stream/all?${new URLSearchParams(params)}`,
  );
  if (response?.msg) {
    dispatch({ type: "streamGetAll/get/reject" });
  } else {
    dispatch({ type: "streamGetAll/get/fulfilled", payload: response });
  }
  return response;
};

export default admin;
