import { getHook, putHook } from "../fetchWrapper/useAxios";
import { fetchExpertStudents } from "./students";

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const works = (state = initialState, action) => {
  switch (action.type) {
    case "works/pending":
      return { ...state, isLoading: true, error: null };
    case "works/fulfilled":
      return { ...state, isLoading: false, data: action.payload, error: null };
    case "works/rejected":
      return { ...state, isLoading: false, error: action.error };

    case "my_works/pending":
      return { ...state, isLoading: true, error: null };
    case "my_works/fulfilled":
      return {
        ...state,
        isLoading: false,
        data: action.payload.items,
        error: null,
      };
    case "my_works/rejected":
      return { ...state, isLoading: false, error: action.error };

    case "works/move/pending":
      return { ...state, isLoading: true, error: null };
    case "works/move/fulfilled":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };
    case "works/move/rejected":
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

export const fetchExpertWorks = (params) => async (dispatch, getState) => {
  dispatch({ type: "works/pending" });
  const response = await getHook(
    dispatch,
    `/expert/works?${new URLSearchParams(params)}`,
  );
  if (response?.detail) {
    dispatch({ type: "works/rejected", error: response?.detail });
  } else {
    dispatch({ type: "works/fulfilled", payload: response });
  }
};

export const moveWorksFromExcel = (data) => async (dispatch) => {
  dispatch({ type: "works/move/pending" });
  let formData = new FormData();
  formData.append("excel_file", data.fileExcel);
  const response = await putHook(
    dispatch,
    `/expert/student/change_expert_students_excel`,
    formData,
  );
  if (response?.detail) {
    dispatch({ type: "works/move/rejected", error: response?.detail });
  } else {
    dispatch({ type: "works/move/fulfilled", payload: response });
    dispatch(fetchExpertStudents());
  }
};

export default works;
