import {
  deleteHook,
  getHook,
  postHook,
  putHook,
} from "../fetchWrapper/useAxios";

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const currencies = (state = initialState, action) => {
  switch (action.type) {
    case "currency/pending":
      return { ...state, isLoading: true };
    case "currency/fulfilled":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };
    case "currency/rejected":
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

export const addCurrency = (name, code, sign) => async (dispatch) => {
  dispatch({ type: "currency/pending" });
  const response = await postHook(dispatch, "/currency/add", {
    name,
    code,
    sign,
  });
  if (response?.detail) {
    dispatch({ type: "currency/rejected", error: response?.detail });
  } else {
    dispatch({ type: "currency/fulfilled", payload: response });
    dispatch(fetchCurrencies());
  }
};

export const editCurrency = (id, name, code, sign) => async (dispatch) => {
  dispatch({ type: "currency/pending" });
  const response = await putHook(dispatch, `/currency/update?id=${id}`, {
    name,
    code,
    sign,
  });
  if (response?.detail) {
    dispatch({ type: "currency/rejected", error: response?.detail });
  } else {
    dispatch({ type: "currency/fulfilled", payload: response });
    dispatch(fetchCurrencies());
  }
};

export const deleteCurrency = (id) => async (dispatch) => {
  dispatch({ type: "currency/pending" });
  const response = await deleteHook(dispatch, `/currency/delete?id=${id}`);
  if (response?.detail) {
    dispatch({ type: "currency/rejected", error: response?.detail });
  } else {
    dispatch({ type: "currency/fulfilled", payload: response });
    dispatch(fetchCurrencies());
  }
};

export const fetchCurrencies = (params) => async (dispatch) => {
  dispatch({ type: "currency/pending" });
  const response = await getHook(
    dispatch,
    `/currency/list?${new URLSearchParams(params)}`
  );
  if (response?.detail) {
    dispatch({ type: "currency/rejected", error: response?.detail });
  } else {
    dispatch({ type: "currency/fulfilled", payload: response });
  }
};

export const getCurrencies = () => async (dispatch) => {
  dispatch({ type: "currency/pending" });
  const response = await getHook(dispatch, "/currency/");
  if (response?.detail) {
    dispatch({ type: "currency/rejected", error: response?.detail });
  } else {
    dispatch({ type: "currency/fulfilled", payload: response });
  }
};
export default currencies;
