import React from 'react';
import Button from '../Button/Button';

import s from './ErrorBoundary.module.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className={s.errorWrapper}>
          <h1 className={s.error}>Что-то пошло нет так. попробуйте обновить страницу</h1>
          <Button
            onClick={() => window.location.reload()}
          >
            Перезагрузить
          </Button>
        </div>
      )
    }

    return children;
  }
}

export default ErrorBoundary;
