import { getHook } from "../fetchWrapper/useAxios";

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const tickers = (state = initialState, action) => {
  switch (action.type) {
    case "tickers/pending":
      return { ...state, isLoading: true, error: null };
    case "tickers/fulfilled":
      return { ...state, isLoading: false, data: action.payload, error: null };
    case "tickers/rejected":
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

export const fetchTickers = (params) => async (dispatch) => {
  dispatch({ type: "tickers/pending" });
  const response = await getHook(
    dispatch,
    `/ticker/ticker?${new URLSearchParams(params)}`,
  );
  if (response?.detail) {
    dispatch({ type: "tickers/rejected", error: response?.detail });
  } else {
    dispatch({ type: "tickers/fulfilled", payload: response });
  }
  return response;
};

export default tickers;
