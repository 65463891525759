const parseJwt = (token) => {
  if (token) {
    const [, payloadBase64] = token?.split(".");
    const normalizedPayload = payloadBase64
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const decodedPayload = atob(normalizedPayload);
    return JSON.parse(
      decodeURIComponent(
        [...decodedPayload]
          .map((char) => `%${`00${char.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(""),
      ),
    );
  }
};
export default parseJwt;
