import { addToast } from "../fetchWrapper/toast";
import {
  deleteHook,
  getHook,
  postHook,
  putHook,
} from "../fetchWrapper/useAxios";

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const students = (state = initialState, action) => {
  switch (action.type) {
    case "students/pending":
      return { ...state, isLoading: true, error: null };
    case "students/fulfilled":
      return { ...state, isLoading: false, data: action.payload, error: null };
    case "students/rejected":
      return { ...state, isLoading: false, error: action.error };

    case "student/add/pending":
      return { ...state, isLoading: true, error: null };
    case "student/add/fulfilled":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };
    case "student/add/rejected":
      return { ...state, isLoading: false, error: action.error };

    case "student/delete/pending":
      return { ...state, isLoading: true, error: null };
    case "student/delete/fulfilled":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };
    case "student/delete/rejected":
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

export const fetchExpertStudents = (params) => async (dispatch) => {
  dispatch({ type: "students/pending" });
  const response = await getHook(
    dispatch,
    `/expert/students?${new URLSearchParams(params)}`
  );
  if (response?.detail) {
    dispatch({ type: "students/rejected", error: response?.detail });
  } else {
    dispatch({ type: "students/fulfilled", payload: response });
  }
};
export const fetchExpertStudentDrafts = (params) => async (dispatch) => {
  dispatch({ type: "students/pending" });
  const response = await getHook(
    dispatch,
    `/expert/drafts?${new URLSearchParams(params)}`
  );
  if (response?.detail) {
    dispatch({ type: "students/rejected", error: response?.detail });
  } else {
    dispatch({ type: "students/fulfilled", payload: response });
  }
};

export const addExpertStudent =
  (email, course, stream, expert, chat, password) => async (dispatch) => {
    dispatch({ type: "student/add/pending" });
    const response = await postHook(dispatch, `/expert/student`, {
      email,
      course,
      stream,
      expert,
      chat,
      password,
    });
    if (response?.detail) {
      dispatch({ type: "student/add/rejected", error: response?.detail });
    } else {
      dispatch({ type: "student/add/fulfilled", payload: response });
      dispatch(fetchExpertStudents());
    }
  };

export const editExpertStudent =
  (id, email, course, stream, expert, chat, password) => async (dispatch) => {
    dispatch({ type: "student/add/pending" });
    const response = await putHook(dispatch, `/expert/student?id=${id}`, {
      email,
      course,
      stream,
      expert,
      chat,
      password,
    });
    if (response?.detail) {
      dispatch({ type: "student/add/rejected", error: response?.detail });
    } else {
      dispatch({ type: "student/add/fulfilled", payload: response });
      dispatch(fetchExpertStudents());
    }
  };

export const changeStreamStudent = (data) => async (dispatch) => {
  dispatch({ type: "student/add/pending" });
  const response = await putHook(
    dispatch,
    `/expert/student/change_stream`,
    data
  );
  if (response?.detail) {
    dispatch({ type: "student/add/rejected", error: response?.detail });
  } else {
    dispatch({ type: "student/add/fulfilled", payload: response });
    dispatch(addToast({ type: "success", text: "Поток студента изменён" }));
    dispatch(fetchExpertStudents());
  }
};

export const changeExpertForStudent = (data) => async (dispatch) => {
  dispatch({ type: "student/add/pending" });
  const response = await putHook(
    dispatch,
    `/expert/student/change_expert`,
    data
  );

  dispatch({ type: "student/add/fulfilled", payload: response });
  dispatch(addToast({ text: "Эксперт студента изменён", type: "success" }));
  dispatch(fetchExpertStudents());
};

export const changePreviousExpert =
  (old_expert, new_expert) => async (dispatch) => {
    dispatch({ type: "student/add/pending" });
    const response = await putHook(
      dispatch,
      `/expert/student/change_expert_students`,
      { old_expert, new_expert }
    );
    if (response?.detail) {
      dispatch({ type: "student/add/rejected", error: response?.detail });
    } else {
      dispatch({ type: "student/add/fulfilled", payload: response });
      dispatch(fetchExpertStudents());
    }
  };

export const addStudentsFromExcel = (data) => async (dispatch) => {
  dispatch({ type: "student/add/pending" });
  let formData = new FormData();
  formData.append("excel_file", data.fileExcel);
  const response = await postHook(dispatch, `/expert/student/excel`, formData);
  if (response?.detail) {
    dispatch({ type: "student/add/rejected", error: response?.detail });
  } else {
    dispatch({ type: "student/add/fulfilled", payload: response });
    dispatch(fetchExpertStudents());
  }
};

export const deleteStudentsFromExcel = (data) => async (dispatch, getState) => {
  dispatch({ type: "student/delete/pending" });
  let formData = new FormData();
  formData.append("excel_file", data.file);
  const response = await deleteHook(
    dispatch,
    `/expert/student/delete_students_excel`,
    formData
  );
  if (response?.detail) {
    dispatch({ type: "student/delete/rejected", error: response?.detail });
  } else {
    dispatch({ type: "student/delete/fulfilled", payload: response });
    dispatch(fetchExpertStudents());
  }
};

export default students;
