import React from 'react';
import classNames from 'classnames';

import s from './Button.module.scss';

const Button = ({
  size = 's',
  variant = 'yellow',
  children,
  onClick,
  title,
  disabled = false,
  stretch = false,
  className,
  leftIcon,
}) => {
  return (
    <button
      className={classNames(
        s.button,
        s[size],
        s[variant],
        stretch && s.stretch,
        disabled && s.disabled,
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      title={title}
    >
      {!!leftIcon && <span className={classNames(s.leftIcon, !children && s.iconWithoutMargins)}>{leftIcon}</span>}
      <span>{children}</span>
    </button>
  )
}

export default Button;
