import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import userData from "./features/userData";
import goal from "./features/goal";
import products from "./features/products";
import assets from "./features/assets";
import dataList from "./features/dataList";
import portfolio from "./features/portfolio";
import works from "./features/works";
import inTheProcess from "./features/inTheProcess";
import readyReports from "./features/readyReports";
import students from "./features/students";
import myPortfiles from "./features/myPortfiles";
import expertAssets from "./features/expertAssets";
import comments from "./features/comments";
import profile from "./features/profile";
import tickers from "./features/tickers";
import courses from "./features/courses";
import experts from "./features/experts";
import currencies from "./features/currencies";
import statistics from "./features/statistics";
import support from "./features/support";
import toasts from "./fetchWrapper/toast/toast";
import images from "./features/imageUpload";
import markowitz from "./features/markowitz";
import admin from "./features/admin";
import userPlans from "./features/plan";

export const store = createStore(
  combineReducers({
    userData,
    goal,
    products,
    assets,
    dataList,
    portfolio,
    works,
    inTheProcess,
    readyReports,
    students,
    myPortfiles,
    expertAssets,
    comments,
    profile,
    tickers,
    courses,
    experts,
    currencies,
    statistics,
    support,
    toasts,
    images,
    markowitz,
    admin,
    userPlans,
  }),
  composeWithDevTools(applyMiddleware(thunk)),
);
