import { getHook } from "../fetchWrapper/useAxios";
const initialState = {
  data: {
    items: [],
  },
  error: null,
  isLoading: true,
  success: null,
};

const myPortfiles = (state = initialState, action) => {
  switch (action.type) {
    case "myPortfiles/pending":
      return { ...state, isLoading: true, error: null };
    case "myPortfiles/fulfilled":
      return { ...state, isLoading: false, data: action.payload, error: null };
    case "myPortfiles/rejected":
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

export const fetchExpertMyPortfiles = (params) => async (dispatch) => {
  dispatch({ type: "myPortfiles/pending" });
  const response = await getHook(
    dispatch,
    `/portfile/my_portfiles?${new URLSearchParams(params)}`
  );
  if (response?.detail) {
    dispatch({ type: "myPortfiles/rejected", error: response.detail });
  } else {
    dispatch({ type: "myPortfiles/fulfilled", payload: response });
  }
};

export default myPortfiles;
