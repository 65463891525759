import React, { useEffect, useState } from "react";
import styles from "./toast.module.sass";
import successIcon from "../../img/toastSuccess.svg";
import errorIcon from "../../img/toastError.svg";
import infoIcon from "../../img/toastInfo.svg";
import closeIcon from "../../img/toastClose.svg";
import { removeToast } from "../../redux/fetchWrapper/toast";
import { useDispatch } from "react-redux";

const Toast = ({ id, type, duration, text, onDismissClick }) => {
  const [toast, setToast] = useState({});
  const [delay, setDelay] = useState(duration);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!delay) {
      return;
    }
    const timeoutId = setInterval(() => {
      dispatch(removeToast(id));
    }, delay);
    return () => {
      clearInterval(timeoutId);
    };
  }, [dispatch, delay, id]);

  useEffect(() => {
    const checkVariant = () => {
      switch (type) {
        case "success":
          setToast({
            title: "Успешно!",
            bg: "#d0f4e2",
            color: "#16C671",
            icon: successIcon,
          });
          break;
        case "error":
          setToast({
            title: "Ошибка!",
            bg: "#ffe5e5",
            color: "#ff4747",
            icon: errorIcon,
          });
          break;
        case "info":
          setToast({
            title: "Success",
            bg: "#5cb85c",
            color: "#d0f4e2",
            icon: infoIcon,
          });
          break;
        default:
          setToast({
            title: "Success",
            bg: "#d0f4e2",
            color: "#d0f4e2",
            icon: successIcon,
          });
          break;
      }
    };
    checkVariant();
  }, [type]);
  return (
    <li
      className={styles.toast}
      style={{ backgroundColor: toast.bg }}
      onMouseEnter={() => setDelay(null)}
      onMouseLeave={() => setDelay(2000)}
    >
      <img src={toast.icon} alt="Icon" />
      <div className={styles.toast__content}>
        <h5 className={styles.title} style={{ color: toast.color }}>
          {toast.title}
        </h5>
        <p className={styles.text}>{text}</p>
      </div>
      <img
        className={styles.toast__dismiss}
        src={closeIcon}
        alt="Close"
        onClick={onDismissClick}
      />
    </li>
  );
};

export default Toast;
