import { memoize } from "memoize-lit";
import { axiosPublic } from "./axiosPublic";

const refreshTokenHook = async () => {
  const refreshToken = localStorage.getItem("refreshToken") || null;
  try {
    const response = await axiosPublic.post("/auth/refresh/", {
      refresh_token: refreshToken,
    });
    const { access_token, refresh_token } = response.data;

    if (!access_token) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }

    localStorage.setItem("accessToken", access_token);
    localStorage.setItem("refreshToken", refresh_token);

    return { accessToken: access_token, refreshToken: refresh_token };
  } catch (error) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }
};

export const memoizedRefreshToken = memoize(refreshTokenHook, {
  maxAge: 100,
});
