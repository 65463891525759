import { getHook } from "../fetchWrapper/useAxios";

const initialState = {
  data: [],
  time: [],
  graphics: [],
  error: null,
  isLoading: false,
};

const statistics = (state = initialState, action) => {
  switch (action.type) {
    case "statistics/pending":
      return { ...state, isLoading: true, error: null };
    case "statistics/fulfilled":
      return { ...state, isLoading: false, items: action.payload, error: null };
    case "statistics/rejected":
      return { ...state, isLoading: false, error: action.error };

    case "actions/pending":
      return { ...state, isLoading: true, error: null };
    case "actions/fulfilled":
      return { ...state, isLoading: false, data: action.payload, error: null };
    case "actions/rejected":
      return { ...state, isLoading: false, error: action.error };

    case "time/pending":
      return { ...state, isLoading: true, error: null };
    case "time/fulfilled":
      return { ...state, isLoading: false, time: action.payload, error: null };
    case "time/rejected":
      return { ...state, isLoading: false, error: action.error };

    case "timeGraphics/pending":
      return { ...state, isLoading: true, error: null };
    case "timeGraphics/fulfilled":
      return {
        ...state,
        isLoading: false,
        graphics: action.payload,
        error: null,
      };
    case "timeGraphics/rejected":
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

export const fetchStatistics = () => async (dispatch) => {
  dispatch({ type: "statistics/pending" });
  const response = await getHook(dispatch, `/expert/platform_statistics`);
  if (response?.detail) {
    dispatch({ type: "statistics/rejected", error: response?.detail });
  } else {
    dispatch({ type: "statistics/fulfilled", payload: response });
  }
};

export const fetchActionList = (params) => async (dispatch) => {
  dispatch({ type: "actions/pending" });
  const response = await getHook(
    dispatch,
    `/expert/actions_list?${new URLSearchParams(params)}`,
  );
  if (response?.detail) {
    dispatch({ type: "actions/rejected", error: response?.detail });
  } else {
    dispatch({ type: "actions/fulfilled", payload: response });
  }
};

export const fetchElapsedTime = (params) => async (dispatch) => {
  dispatch({ type: "time/pending" });
  const response = await getHook(
    dispatch,
    `/expert/elapsed_time_list?${new URLSearchParams(params)}`,
  );
  if (response?.detail) {
    dispatch({ type: "time/rejected", error: response?.detail });
  } else {
    dispatch({ type: "time/fulfilled", payload: response });
  }
};

export const fetchElapsedTimeGraphics = () => async (dispatch) => {
  dispatch({ type: "timeGraphics/pending" });
  const response = await getHook(dispatch, `/expert/elapsed_time_diagram_data`);
  if (response?.detail) {
    dispatch({ type: "timeGraphics/rejected", error: response?.detail });
  } else {
    dispatch({ type: "timeGraphics/fulfilled", payload: response });
  }
};

export default statistics;
