import { addToast } from "../fetchWrapper/toast";
import {
  deleteHook,
  getHook,
  postHook,
  putHook,
} from "../fetchWrapper/useAxios";
const initialState = {
  data: [],
  risky: [],
  conserv: [],
  security: [],
  commentAsset: {},
  user: {},
  comments: [],
  error: null,
  loading: false,
  tacticComment: [],
  estate: [],
  crypto: [],
  options: [],
  futures: [],
  etf: [],
  bond: [],
  stock: [],
  activeCommentAsset: {},
  activeCommentTactic: {},
  activeCommentGeneral: {},
};

const comments = (state = initialState, action) => {
  switch (action.type) {
    case "comment/create/pending":
      return { ...state, loading: true, error: null, success: null };
    case "comment/create/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "comment/create/rejected":
      return { ...state, error: action.error };
    case "activeCommentGeneral/create/fulfilled":
      return {
        ...state,
        activeCommentGeneral: action.payload,
      };
    case "activeCommentAsset/create/fulfilled":
      return {
        ...state,
        activeCommentAsset: action.payload,
      };

    case "activeCommentTactic/create/fulfilled":
      return {
        ...state,
        activeCommentTactic: action.payload,
      };

    case "tacticComments/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "tacticComments/get/fulfilled":
      return {
        ...state,
        loading: false,
        tacticComment: action.payload,
        success: true,
      };
    case "tacticComments/get/rejected":
      return { ...state, error: action.error };

    case "comment/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "comment/get/fulfilled":
      return {
        ...state,
        loading: false,
        comments: action.payload,
        success: true,
      };
    case "comment/get/rejected":
      return { ...state, error: action.error };

    case "estate/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "estate/get/fulfilled":
      return {
        ...state,
        loading: false,
        estate: action.payload,
        success: true,
      };
    case "estate/get/rejected":
      return { ...state, error: action.error };

    case "crypto/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "crypto/get/fulfilled":
      return {
        ...state,
        loading: false,
        crypto: action.payload,
        success: true,
      };
    case "crypto/get/rejected":
      return { ...state, error: action.error };

    case "options/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "options/get/fulfilled":
      return {
        ...state,
        loading: false,
        options: action.payload,
        success: true,
      };
    case "options/get/rejected":
      return { ...state, error: action.error };

    case "futures/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "futures/get/fulfilled":
      return {
        ...state,
        loading: false,
        futures: action.payload,
        success: true,
      };
    case "futures/get/rejected":
      return { ...state, error: action.error };

    case "etf/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "etf/get/fulfilled":
      return {
        ...state,
        loading: false,
        etf: action.payload,
        success: true,
      };
    case "etf/get/rejected":
      return { ...state, error: action.error };

    case "bond/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "bond/get/fulfilled":
      return {
        ...state,
        loading: false,
        bond: action.payload,
        success: true,
      };
    case "bond/get/rejected":
      return { ...state, error: action.error };

    case "stock/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "stock/get/fulfilled":
      return {
        ...state,
        loading: false,
        stock: action.payload,
        success: true,
      };
    case "stock/get/rejected":
      return { ...state, error: action.error };

    case "commentAssets/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "commentAssets/get/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "commentAssets/get/rejected":
      return { ...state, error: action.error };

    case "commentRisky/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "commentRisky/get/fulfilled":
      return { ...state, loading: false, risky: action.payload, success: true };
    case "commentRisky/get/rejected":
      return { ...state, error: action.error };

    case "commentConserv/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "commentConserv/get/fulfilled":
      return {
        ...state,
        loading: false,
        conserv: action.payload,
        success: true,
      };
    case "commentConserv/get/rejected":
      return { ...state, error: action.error };

    case "commentSecurity/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "commentSecurity/get/fulfilled":
      return {
        ...state,
        loading: false,
        security: action.payload,
        success: true,
      };
    case "commentSecurity/get/rejected":
      return { ...state, error: action.error };

    case "commentAsset/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "commentAsset/get/fulfilled":
      return {
        ...state,
        loading: false,
        commentAsset: action.payload,
        success: true,
      };
    case "commentAsset/get/rejected":
      return { ...state, error: action.error };

    case "comment/edit/pending":
      return { ...state, loading: true, error: null, success: null };
    case "comment/edit/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "comment/edit/rejected":
      return { ...state, error: action.error };

    case "comment/delete/pending":
      return { ...state, loading: true, error: null, success: null };
    case "comment/delete/fulfilled":
      return {
        ...state,
        loading: false,
        data: action.payload.items,
        success: true,
      };
    case "comment/delete/rejected":
      return { ...state, error: action.error };

    default:
      return state;
  }
};
const findComment = (title) => {
  switch (title) {
    case "Недвижимость":
      return "estate";
    case "Криптовалюта":
      return "crypto";
    case "Опционы":
      return "options";
    case "Фьючерсы":
      return "futures";
    case "ETF":
      return "etf";
    case "Облигации":
      return "bond";
    case "Акции":
      return "stock";
    default:
      return "stock";
  }
};
export const addActiveCommentGeneral = (data) => async (dispatch) => {
  dispatch({
    type: "activeCommentGeneral/create/fulfilled",
    payload: data,
  });
};
export const addActiveCommentAsset = (data) => async (dispatch) => {
  dispatch({
    type: "activeCommentAsset/create/fulfilled",
    payload: data,
  });
};
export const addActiveCommentTactic = (data) => async (dispatch) => {
  dispatch({
    type: "activeCommentTactic/create/fulfilled",
    payload: data,
  });
};

export const addFinalComment = (comment, portfolioId) => async (dispatch) => {
  dispatch({ type: "comment/create/pending" });
  const response = await postHook(
    dispatch,
    `/expert/final_comment?id=${portfolioId}`,
    { final_comment: comment },
  );
  if (response?.detail) {
    dispatch({
      type: "comment/create/rejected",
      error: response?.detail,
    });
  } else {
    dispatch({
      type: "comment/create/fulfilled",
      payload: response,
    });
    dispatch(addToast({ text: "Комментарий сохранен", type: "success" }));
  }
};

export const addCommentGoal = (commentGoal, goalId, id) => async (dispatch) => {
  dispatch({ type: "comment/create/pending" });
  const response = await postHook(
    dispatch,
    `/expert/comment_on_goals?id=${goalId}`,
    { comment_no_goals: commentGoal },
  );
  if (response?.detail) {
    dispatch({ type: "comment/create/rejected", error: response?.detail });
  } else {
    dispatch({ type: "comment/create/fulfilled", payload: response });
    dispatch(addToast({ text: "Комментарий сохранен", type: "success" }));
  }
};

export const addCommentTactical = (comment, goalId, id) => async (dispatch) => {
  dispatch({ type: "comment/create/pending" });
  const response = await postHook(
    dispatch,
    `/expert/comment_tactical_strategy?id=${goalId}`,
    { comment_no_tactical_strategy: comment },
  );
  if (response?.detail) {
    dispatch({ type: "comment/create/rejected", error: response?.detail });
  } else {
    dispatch({ type: "comment/create/fulfilled", payload: response });
    dispatch(addToast({ text: "Комментарий сохранен", type: "success" }));
  }
};

export const addCommentAssets =
  (commentAssets, goalId, id) => async (dispatch) => {
    dispatch({ type: "comment/create/pending" });
    const response = await postHook(
      dispatch,
      `/expert/comment_on_asset?id=${goalId}`,
      { comment_no_assets: commentAssets },
    );
    if (response?.detail) {
      dispatch({ type: "comment/create/rejected", error: response?.detail });
    } else {
      dispatch({ type: "comment/create/fulfilled", payload: response });
      dispatch(addToast({ text: "Комментарий сохранен", type: "success" }));
    }
  };

export const addCommentPortfolio =
  (commentPortfolio, goalId, id) => async (dispatch, getState) => {
    dispatch({ type: "comment/create/pending" });
    const response = await postHook(
      dispatch,
      `/expert/comment_on_portfile?id=${goalId}`,
      { comment_no_portfile: commentPortfolio },
    );
    if (response?.detail) {
      dispatch({ type: "comment/create/rejected", error: response?.detail });
    } else {
      dispatch({ type: "comment/create/fulfilled", payload: response });
      dispatch(addToast({ text: "Комментарий сохранен", type: "success" }));
    }
  };

export const addCommentPortfolioExpert =
  (commentPortfolioExpert, goalId, id) => async (dispatch) => {
    dispatch({ type: "comment/create/pending" });
    const response = await postHook(
      dispatch,
      `/expert/comment_from_expert?id=${goalId}`,
      { comment_from_expert: commentPortfolioExpert },
    );
    if (response?.detail) {
      dispatch({ type: "comment/create/rejected", error: response?.detail });
    } else {
      dispatch({ type: "comment/create/fulfilled", payload: response });
      dispatch(addToast({ text: "Комментарий сохранен", type: "success" }));
    }
  };

export const addCommentGeneral =
  (course_id, comment_to, comment) => async (dispatch) => {
    dispatch({ type: "comment/create/pending" });
    const response = await postHook(dispatch, `/expert/blank_general_comment`, {
      comment_to,
      comment,
      course_id,
    });
    if (response?.detail) {
      dispatch({ type: "comment/create/rejected", error: response?.detail });
    } else {
      dispatch(getAllCommentGeneral(course_id));
      dispatch({ type: "comment/create/fulfilled", payload: response });
      dispatch(addToast({ text: "Комментарий сохранен", type: "success" }));
    }
  };

export const getCommentGeneral = (comment_to) => async (dispatch, getState) => {
  const state = getState();
  dispatch({ type: "comment/get/pending" });
  const response = await getHook(
    dispatch,
    `/expert/blank_general_comment?course_id=${
      state.courses.course?.course_id
    }${comment_to ? `&comment_to=${comment_to}` : ""}`,
  );
  if (response?.detail) {
    dispatch({ type: "comment/get/rejected", error: response?.detail });
  } else {
    dispatch({ type: "comment/get/fulfilled", payload: response });
  }
};
export const getAllCommentGeneral = (id) => async (dispatch) => {
  dispatch({ type: "comment/get/pending" });
  const response = await getHook(
    dispatch,
    `/expert/blank_general_comment?course_id=${id}`,
  );
  if (response?.detail) {
    dispatch({ type: "comment/get/rejected", error: response?.detail });
  } else {
    dispatch({ type: "comment/get/fulfilled", payload: response });
  }
};

export const addCommentAssetsGeneral =
  (asset_part, type_inctrument, ticker, comment, params) =>
  async (dispatch) => {
    dispatch({ type: "comment/create/pending" });
    const response = await postHook(dispatch, `/expert/blank_asset_comment`, {
      asset_part,
      tiker_name: ticker,
      type_inctrument: type_inctrument,
      comment,
    });
    if (response?.detail) {
      dispatch({ type: "comment/create/rejected", error: response?.detail });
    } else {
      params && dispatch(getCommentAssetsGeneral(asset_part, params));
      dispatch({ type: "comment/create/fulfilled", payload: response });
      dispatch(addToast({ text: "Комментарий сохранен", type: "success" }));
    }
  };
export const addMethodCommentAssetsGeneral =
  (data, params) => async (dispatch) => {
    dispatch({ type: "comments/create/pending" });
    const response = await postHook(
      dispatch,
      `/expert/blank_asset_comment`,
      data,
    );
    if (response?.detail) {
      dispatch({
        type: "comments/create/rejected",
        error: response?.detail,
      });
    } else {
      setTimeout(() => {
        dispatch(
          getMethodCommentAssetsGeneral(
            data.asset_part,
            data.type_inctrument,
            params,
          ),
        );
      }, 500);

      dispatch({ type: "comments/create/fulfilled", payload: response });
      dispatch(addToast({ text: "Комментарий сохранен", type: "success" }));
    }
  };
export const importMethodCommentAssetsGeneral =
  (data, params) => async (dispatch) => {
    const file = data.file;
    const excel_file = new FormData();
    excel_file.append("excel_file", file);
    dispatch({ type: "comments/create/pending" });
    const response = await postHook(
      dispatch,
      `/expert/blank_comment/upload?asset_part=${data.asset_part}${
        data?.type_inctrument ? `&instrument=${data.type_inctrument}` : ""
      }`,
      excel_file,
    );
    if (response?.detail) {
      dispatch({
        type: "comments/create/rejected",
        error: response?.detail,
      });
    } else {
      dispatch({ type: "comments/create/fulfilled", payload: response });
      dispatch(
        getMethodCommentAssetsGeneral(
          data.asset_part,
          data.type_inctrument,
          params,
        ),
      );
      dispatch(addToast({ text: "Файл успешно загружен", type: "success" }));
    }
  };

export const getCommentAssetsGeneral =
  (asset_part, params) => async (dispatch) => {
    if (asset_part === "Рисковая часть") {
      dispatch({ type: "commentRisky/get/pending" });
    } else if (asset_part === "Консервативная часть") {
      dispatch({ type: "commentConserv/get/pending" });
    } else {
      dispatch({ type: "commentSecurity/get/pending" });
    }
    const response = await getHook(
      dispatch,
      `/expert/blank_asset_comment?asset_part=${asset_part}${
        params ? `&${new URLSearchParams(params)}` : ""
      }`,
    );
    if (response?.detail) {
      if (asset_part === "Рисковая часть") {
        dispatch({
          type: "commentRisky/get/rejected",
          error: response?.detail,
        });
      } else if (asset_part === "Консервативная часть") {
        dispatch({
          type: "commentConserv/get/rejected",
          error: response?.detail,
        });
      } else {
        dispatch({
          type: "commentSecurity/get/rejected",
          error: response?.detail,
        });
      }
    } else {
      if (asset_part === "Рисковая часть") {
        dispatch({ type: "commentRisky/get/fulfilled", payload: response });
      } else if (asset_part === "Консервативная часть") {
        dispatch({ type: "commentConserv/get/fulfilled", payload: response });
      } else {
        dispatch({ type: "commentSecurity/get/fulfilled", payload: response });
      }
      return response?.items;
    }
  };
export const getMethodCommentAssetsGeneral =
  (asset_part, type, params) => async (dispatch) => {
    const dispatchTitle = findComment(type);
    dispatch({ type: `${dispatchTitle}/get/pending` });
    const response = await getHook(
      dispatch,
      `/expert/blank_asset_comment?asset_part=${asset_part}${
        type ? `&type_inctrument=${type}` : ""
      }${params ? `&${new URLSearchParams(params)}` : ""}`,
    );
    if (response?.detail) {
      dispatch({
        type: `${dispatchTitle}/get/rejected`,
        error: response?.detail,
      });
    } else {
      dispatch({
        type: `${dispatchTitle}/get/fulfilled`,
        payload: response,
      });
      return response?.items;
    }
  };
export const getCommentAssets = (asset_part, params) => async (dispatch) => {
  dispatch({ type: "commentAsset/get/pending" });
  const response = await getHook(
    dispatch,
    `/expert/blank_asset_comment?asset_part=${asset_part}${
      params ? `&search=${params}` : ``
    }`,
  );
  if (response?.detail) {
    dispatch({ type: "commentAsset/get/rejected", error: response?.detail });
  } else {
    dispatch({
      type: "commentAsset/get/fulfilled",
      payload: response?.items[0],
    });
  }
  return response?.items[0];
};
export const getCommentAssetsByTicker = (data) => async (dispatch) => {
  dispatch({ type: "commentAsset/get/pending" });
  const response = await getHook(
    dispatch,
    `/expert/blank_asset_ticker_comment?` + new URLSearchParams(data),
  );
  if (response?.detail) {
    dispatch({ type: "commentAsset/get/rejected", error: response?.detail });
  } else {
    dispatch({
      type: "commentAsset/get/fulfilled",
      payload: response,
    });
  }
  return response;
};

export const editCommentAssetsGeneral =
  (asset_part, assetType, ticker, comment, id, params) => async (dispatch) => {
    dispatch({ type: "comment/edit/pending" });
    const response = await putHook(
      dispatch,
      `/expert/blank_asset_comment?id=${id}`,
      { asset_part, tiker_name: ticker, type_inctrument: assetType, comment },
    );
    if (response?.detail) {
      dispatch({ type: "comment/edit/rejected", error: response?.detail });
    } else {
      dispatch(getCommentAssetsGeneral(asset_part, params));
      dispatch({ type: "comment/edit/fulfilled", payload: response });
      dispatch(
        addToast({ text: "Комментарий откорректирован", type: "success" }),
      );
    }
  };

export const deleteAllAssetsGeneral = (data, params) => async (dispatch) => {
  dispatch({ type: "comment/delete/pending" });
  const response = await deleteHook(
    dispatch,
    `/expert/blank_asset_comment_all`,
    data,
  );
  if (response?.detail) {
    dispatch({ type: "comment/delete/rejected", error: response?.detail });
  } else {
    dispatch(getCommentAssetsGeneral(data.asset_part, params));
    dispatch({ type: "comment/delete/fulfilled", payload: response });
    dispatch(addToast({ text: "Комментарий удалены", type: "success" }));
  }
};
export const editMethodCommentAssetsGeneral =
  (data, params) => async (dispatch) => {
    dispatch({ type: "comment/edit/pending" });
    const response = await putHook(
      dispatch,
      `/expert/blank_asset_comment?id=${data.id}`,
      data,
    );
    if (response?.detail) {
      dispatch({ type: "comment/edit/rejected", error: response?.detail });
    } else {
      dispatch({ type: "comment/edit/fulfilled", payload: response });
      setTimeout(() => {
        dispatch(
          getMethodCommentAssetsGeneral(
            data.asset_part,
            data.type_inctrument,
            params,
          ),
        );
      }, 500);
      dispatch(
        addToast({ text: "Комментарий откорректирован", type: "success" }),
      );
    }
  };

export const deleteCommentAssetsGeneral = (id, params) => async (dispatch) => {
  dispatch({ type: "comment/delete/pending" });
  const response = await deleteHook(
    dispatch,
    `/expert/blank_asset_comment?id=${id}`,
  );
  dispatch({ type: "comment/delete/fulfilled", payload: response });
  if (response?.detail) {
    dispatch({ type: "comment/delete/rejected", error: response?.detail });
    dispatch(addToast({ text: "Комментарий удален", type: "success" }));
  }
};

export default comments;
