import { getHook, postHook } from "../fetchWrapper/useAxios";

const initialState = {
  data: {},
  error: null,
  loading: false,
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case "products/create/pending":
      return { ...state, loading: true, error: null, success: null };
    case "products/create/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "products/create/rejected":
      return { ...state, error: action.error };

    case "products/load/pending":
      return { ...state, loading: true, error: null, success: null };
    case "products/load/fulfilled":
      return { ...state, loading: false, data: action.payload };
    case "products/load/rejected":
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export const createProductsInsurance =
  (insurance_product, name, program_cost, currency, insurance_company, id) =>
  async (dispatch) => {
    dispatch({ type: "products/create/pending" });
    const response = await postHook(
      dispatch,
      `/portfile/insurance-products?portfile_id=${id}`,
      {
        insurance_product,
        name,
        program_cost,
        currency,
        insurance_company,
      }
    );
    if (response?.detail) {
      dispatch({ type: "products/create/rejected", error: response.detail });
    } else {
      dispatch({ type: "products/create/fulfilled", payload: response });
    }
  };

export const getProductsInsurance = (id) => async (dispatch) => {
  dispatch({ type: "products/load/pending" });
  const response = await getHook(
    dispatch,
    `/portfile/insurance-products?briefcase_id=${id}`
  );
  if (response?.detail) {
    dispatch({ type: "products/load/rejected", error: response.detail });
  } else {
    dispatch({ type: "products/load/fulfilled", payload: response });
  }
};

export default products;
