import { getHook } from "../fetchWrapper/useAxios";
const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const inTheProcess = (state = initialState, action) => {
  switch (action.type) {
    case "inTheProcess/pending":
      return { ...state, isLoading: true, error: null };
    case "inTheProcess/fulfilled":
      return { ...state, isLoading: false, data: action.payload, error: null };
    case "inTheProcess/rejected":
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

export const fetchExpertInTheProcess = (params) => async (dispatch) => {
  dispatch({ type: "inTheProcess/pending" });
  const response = await getHook(
    dispatch,
    `/expert/in_the_process?${new URLSearchParams(params)}`
  );
  if (response?.detail) {
    dispatch({ type: "inTheProcess/rejected", error: response.detail });
  } else {
    dispatch({ type: "inTheProcess/fulfilled", payload: response });
  }
};

export default inTheProcess;
