import { API_URL, PDF_URL } from "../config";
import { axiosPrivate } from "./axiosPrivate";
import { addToast } from "./toast";

export const postHook = async (dispatch, url, payload) => {
  try {
    const res = await axiosPrivate.post(`${API_URL}${url}`, payload);
    return res?.data;
  } catch (e) {
    const error = e?.response?.data;
    if (Array.isArray(error?.detail)) {
      error?.detail?.forEach((el) => {
        dispatch(
          addToast({
            text: el?.msg
              ? el?.msg
              : error?.detail?.message
              ? error?.detail?.message
              : error?.detail,
            type: "error",
          }),
        );
      });
    } else {
      dispatch(
        addToast({
          text: error?.detail?.message ? error?.detail?.message : error?.detail,
          type: "error",
        }),
      );
    }

    return error;
  }
};
export const getHook = async (dispatch, url, payload) => {
  try {
    const res = await axiosPrivate.get(`${API_URL}${url}`, payload);
    return res?.data;
  } catch (e) {
    const error = e?.response?.data;
    if (Array.isArray(error?.detail)) {
      error?.detail?.forEach((el) => {
        dispatch(
          addToast({
            text: el?.msg
              ? el?.msg
              : error?.detail?.message
              ? error?.detail?.message
              : error?.detail,
            type: "error",
          }),
        );
      });
    } else {
      dispatch(
        addToast({
          text: error?.detail?.message ? error?.detail?.message : error?.detail,
          type: "error",
        }),
      );
    }
    return error;
  }
};

export const putHook = async (dispatch, url, payload) => {
  try {
    const res = await axiosPrivate.put(`${API_URL}${url}`, payload);
    return res?.data;
  } catch (e) {
    const error = e?.response?.data;
    if (Array.isArray(error?.detail)) {
      error?.detail?.forEach((el) => {
        dispatch(
          addToast({
            text: el?.msg
              ? el?.msg
              : error?.detail?.message
              ? error?.detail?.message
              : error?.detail,
            type: "error",
          }),
        );
      });
    } else {
      dispatch(
        addToast({
          text: error?.detail?.message ? error?.detail?.message : error?.detail,
          type: "error",
        }),
      );
    }
    return error;
  }
};
export const deleteHook = async (dispatch, url, payload) => {
  try {
    const res = await axiosPrivate.delete(`${API_URL}${url}`, {
      data: payload,
    });
    return res?.data;
  } catch (e) {
    const error = e?.response?.data;
    if (Array.isArray(error?.detail)) {
      error?.detail?.forEach((el) => {
        dispatch(
          addToast({
            text: el?.msg
              ? el?.msg
              : error?.detail?.message
              ? error?.detail?.message
              : error?.detail,
            type: "error",
          }),
        );
      });
    } else {
      dispatch(
        addToast({
          text: error?.detail?.message ? error?.detail?.message : error?.detail,
          type: "error",
        }),
      );
    }
    return error;
  }
};
export const pdfMakePostHook = async (dispatch, url, payload) => {
  try {
    const res = await axiosPrivate.post(`${PDF_URL}${url}`, payload, {
      headers: {
        "Content-Type": "application/json",
        Token: process.env.REACT_APP_PDF_GENERATE_TOKEN,
      },
    });
    return res?.data;
  } catch (e) {
    const error = e?.response?.data;
    if (Array.isArray(error?.detail)) {
      error?.detail?.forEach((el) => {
        dispatch(
          addToast({
            text: el?.msg
              ? el?.msg
              : error?.detail?.message
              ? error?.detail?.message
              : error?.detail,
            type: "error",
          }),
        );
      });
    } else {
      dispatch(
        addToast({
          text: error?.detail?.message ? error?.detail?.message : error?.detail,
          type: "error",
        }),
      );
    }

    return error;
  }
};
export const pdfMakeGetHook = async (dispatch, url, payload) => {
  try {
    const res = await axiosPrivate.get(`${PDF_URL}${url}`, payload, {
      headers: {
        "Content-Type": "application/json",
        Token: process.env.REACT_APP_PDF_GENERATE_TOKEN,
      },
    });
    return res?.data;
  } catch (e) {
    const error = e?.response?.data;
    if (Array.isArray(error?.detail)) {
      error?.detail?.forEach((el) => {
        dispatch(
          addToast({
            text: el?.msg
              ? el?.msg
              : error?.detail?.message
              ? error?.detail?.message
              : error?.detail,
            type: "error",
          }),
        );
      });
    } else {
      dispatch(
        addToast({
          text: error?.detail?.message ? error?.detail?.message : error?.detail,
          type: "error",
        }),
      );
    }

    return error;
  }
};

export const imageDownloadHook = async (dispatch, url, payload) => {
  try {
    const res = await axiosPrivate.post(`${API_URL}${url}`, payload);
    return res?.data;
  } catch (e) {
    const error = e?.response?.data;
    if (Array.isArray(error?.detail)) {
      error?.detail?.forEach((el) => {
        dispatch(
          addToast({
            text: el?.msg
              ? el?.msg
              : error?.detail?.message
              ? error?.detail?.message
              : error?.detail,
            type: "error",
          }),
        );
      });
    } else {
      dispatch(
        addToast({
          text: error?.detail?.message ? error?.detail?.message : error?.detail,
          type: "error",
        }),
      );
    }

    return error;
  }
};
