import {
  deleteHook,
  getHook,
  postHook,
  putHook,
} from "../fetchWrapper/useAxios";

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const support = (state = initialState, action) => {
  switch (action.type) {
    case "support/pending":
      return { ...state, isLoading: true };
    case "support/fulfilled":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };
    case "support/rejected":
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

export const addSupport = (data) => async (dispatch) => {
  dispatch({ type: "support/pending" });
  let formData = new FormData();
  formData.append("avatar", data.avatar);
  formData.append("fullname", data.fullname);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("password", data.password);
  const response = await postHook(dispatch, `/expert/add_support`, formData);
  if (response?.detail) {
    dispatch({ type: "support/rejected", error: response?.detail });
  } else {
    dispatch({ type: "support/fulfilled", payload: response });
    dispatch(fetchSupport());
  }
};

export const editSupport = (id, data) => async (dispatch) => {
  dispatch({ type: "support/pending" });
  let formData = new FormData();
  formData.append("avatar", data.avatar);
  formData.append("fullname", data.fullname);
  formData.append("phone", data.phone);
  formData.append("password", data.password);
  const response = await putHook(
    dispatch,
    `/expert/support?id=${id}`,
    formData,
  );
  if (response?.detail) {
    dispatch({ type: "support/rejected", error: response?.detail });
  } else {
    dispatch({ type: "support/fulfilled", payload: response });
    dispatch(fetchSupport());
  }
};

export const deleteSupport = (id) => async (dispatch) => {
  dispatch({ type: "support/pending" });
  const response = await deleteHook(dispatch, `/expert/support?id=${id}`);
  if (response?.detail) {
    dispatch({ type: "support/rejected", error: response?.detail });
  } else {
    dispatch({ type: "support/fulfilled", payload: response });
    dispatch(fetchSupport());
  }
};

export const fetchSupport = (params) => async (dispatch) => {
  dispatch({ type: "support/pending" });
  const response = await getHook(
    dispatch,
    `/expert/supports?${new URLSearchParams(params)}`,
  );
  if (response?.detail) {
    dispatch({ type: "support/rejected", error: response?.detail });
  } else {
    dispatch({ type: "support/fulfilled", payload: response });
  }
};

export const editActiveSupport = (id, isActive) => async (dispatch) => {
  dispatch({ type: "support/pending" });
  const response = await putHook(
    dispatch,
    `/expert/support/active?id=${id}&active=${isActive}`,
  );
  if (response?.detail) {
    dispatch({ type: "support/rejected", error: response?.detail });
  } else {
    dispatch({ type: "support/fulfilled", payload: response });
    dispatch(fetchSupport());
  }
};

export default support;
