import React from "react";
import { useDispatch } from "react-redux";
import Toast from "./Toast";
import { removeToast } from "../../redux/fetchWrapper/toast";
import styles from "./toast.module.sass";

const Toasts = ({ toasts }) => {
  const dispatch = useDispatch();
  return (
    <ul className={styles.toasts}>
      {toasts?.map(({ id, duration, text, type }) => {
        return (
          <Toast
            type={type}
            text={text}
            duration={duration}
            key={id}
            id={id}
            onDismissClick={() => dispatch(removeToast(id))}
          />
        );
      })}
    </ul>
  );
};

export default Toasts;
