import { addToast } from "../fetchWrapper/toast";
import { getHook } from "../fetchWrapper/useAxios";
import { getFinPlanStudent, getPortfolioStudent } from "./portfolio";

const initialState = {
  data: [],
  error: null,
  loading: false,
};

const markowitz = (state = initialState, action) => {
  switch (action.type) {
    case "markowitz/pending":
      return { ...state, loading: true, error: null, success: null };
    case "markowitz/fulfilled":
      return { ...state, loading: false, data: action.payload };
    case "markowitz/rejected":
      return { ...state, error: action.error };
    default:
      return state;
  }
};
export const getMarkowitz =
  (params, portfolioId, user_id, goal_id, text) => async (dispatch) => {
    dispatch({ type: "markowitz/pending" });
    const response = await getHook(
      dispatch,
      `/expert/rebalancing?${new URLSearchParams(params)}`,
    );
    if (response?.status_code === 200) {
      dispatch({ type: "markowitz/fulfilled", payload: response });
      dispatch(getPortfolioStudent(portfolioId, user_id));
      dispatch(getFinPlanStudent(goal_id));
      dispatch(
        addToast({
          type: "success",
          text: text,
        }),
      );
    } else {
      dispatch({ type: "markowitz/rejected", error: response.detail });
    }
    return response;
  };

export default markowitz;
