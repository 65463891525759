import { addToast } from "../fetchWrapper/toast";
import {
  deleteHook,
  getHook,
  postHook,
  putHook,
} from "../fetchWrapper/useAxios";
import { getPortfileStudent } from "./portfolio";
const initialState = {
  data: [],
  error: null,
  loading: false,
  goalCount: 0,
  goal_id: localStorage.getItem("goal_id"),
  course: {},
  hasInsurance: false,
  sendVerification: false,
  finansyPlan: [],
  currencies: [],
  broker_list: [],
  broker_country: "",
};

const goal = (state = initialState, action) => {
  switch (action.type) {
    case "goal/create/pending":
      return { ...state, loading: true, error: null };
    case "goal/create/fulfilled":
      return {
        ...state,
        loading: false,
        data: action.payload.goal,
        goalCount: action.payload.goal?.length,
        briefcaseId: action.payload.id,
        success: true,
      };
    case "goal/create/rejected":
      return { ...state, error: action.error };

    // TODO: Костыль?
    case "goal/clear":
      return {
        goalCount: 0,
        loading: false,
        error: null,
        goal_id: null,
        data: [],
      };
    case "goal/load/pending":
      return { ...state, loading: true, error: null };
    case "goal/load/fulfilled":
      return {
        ...state,
        loading: false,
        reportStatus: action.payload.report_status,
        goal_id: action.payload.goal_id,
        goalCount: action.payload?.goals_count,
        course_id: action.payload?.portfile_course,
        finalComment: action.payload?.final_comment,
        success: true,
        hasInsurance: action.payload?.has_insurance_product,
        sendVerification: action.payload?.sending_for_verification,
      };
    case "goal/load/rejected":
      return { ...state, error: action.error };
    case "goals/load/pending":
      return { ...state, loading: true, error: null };
    case "goals/load/fulfilled":
      return {
        ...state,
        loading: false,
        data: action.payload,
        success: true,
      };
    case "goals/load/rejected":
      return { ...state, error: action.error };
    case "fynansy/load/rejected":
      return { ...state, error: action.error };
    case "fynansy/load/pending":
      return { ...state, loading: true, error: null };
    case "fynansy/load/fulfilled":
      return {
        ...state,
        loading: false,
        finansyPlan: action.payload,
        success: true,
      };
    case "course/load/pending":
      return { ...state, loading: true, error: null };
    case "course/load/fulfilled":
      return {
        ...state,
        course: action.payload,
      };
    case "course/load/rejected":
      return { ...state, error: action.error };

    case "goal/profileGoals/pending":
      return { ...state, loading: true, error: null };
    case "goal/profileGoals/fulfilled":
      const goalCount = action.payload.goal.length;
      return {
        ...state,
        loading: false,
        data: action.payload,
        goalCount,
        success: true,
      };
    case "goal/profileGoals/rejected":
      return { ...state, data: [], error: action.error };

    case "goal/update/pending":
      return { ...state, loading: true, error: null };
    case "goal/update/fulfilled":
      return {
        ...state,
        loading: false,
        data: action.payload.goal,
        reportStatus: action.payload.report_status,
        success: true,
        goalCount: action.payload.goal?.length || 0,
      };
    case "goal/update/rejected":
      return { ...state, error: action.error };

    case "country/load/fulfilled":
      return { ...state, loading: false, settings: action.payload };
    case "country/load/rejected":
      return { ...state, loading: false, error: action.error };

    case "goalStudent/update/pending":
      return { ...state, loading: true, error: null };
    case "goalStudent/update/fulfilled":
      return { ...state, loading: false, data: action.payload };
    case "goalStudent/update/rejected":
      return { ...state, loading: false, error: action.error };

    case "goalCurrency/load/pending":
      return { ...state, loading: true, error: null };
    case "goalCurrency/load/fulfilled":
      return {
        ...state,
        currencies: action.payload,
      };
    case "goalCurrency/load/rejected":
      return { ...state, error: action.error };
    case "goalBroker/load/pending":
      return { ...state, loading: true, error: null };
    case "goalBroker/load/fulfilled":
      return {
        ...state,
        broker_list: action.payload?.brokers,
        broker_country: action.payload?.county,
      };
    case "goalBroker/load/rejected":
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export const getRobotBroker = (country, currency_id) => async (dispatch) => {
  dispatch({ type: "goalBroker/load/pending" });
  const response = await getHook(
    dispatch,
    `/robot/broker?country=${country}&currency_id=${currency_id}`,
  );
  if (response?.detail) {
    dispatch({ type: "goalBroker/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "goalBroker/load/fulfilled", payload: response });
  }
  return response;
};
export const getRobotCurrency = (country, gos_status) => async (dispatch) => {
  dispatch({ type: "goalCurrency/load/pending" });
  const response = await getHook(
    dispatch,
    `/currency/robot_currency?country=${country}&gos_status=${gos_status}`,
  );
  if (response?.detail) {
    dispatch({ type: "goalCurrency/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "goalCurrency/load/fulfilled", payload: response });
  }
};
export const getGoal = (id) => async (dispatch) => {
  dispatch({ type: "goal/load/pending" });
  const response = await getHook(dispatch, `/portfile/goals?id=${id}`);
  if (response?.detail) {
    dispatch({ type: "goal/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "goal/load/fulfilled", payload: response });
  }
};
export const getFinansyPlan = (id, check) => async (dispatch) => {
  dispatch({ type: "fynansy/load/pending" });
  const response = await getHook(
    dispatch,
    `/portfile/financial_plan?briefcase_id=${
      check ? `${id}&checked=${check}` : `${id}`
    }`,
  );
  if (response?.detail) {
    dispatch({ type: "fynansy/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "fynansy/load/fulfilled", payload: response });
  }
};
export const getPortfile = (id) => async (dispatch) => {
  dispatch({ type: "goal/load/pending" });
  const response = await getHook(
    dispatch,
    `/portfile/briefcase?briefcase_id=${id}`,
  );
  if (response?.detail) {
    dispatch({ type: "goal/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "goal/load/fulfilled", payload: response });
    dispatch(getGoals(id));
  }
};
export const getGoals = (id) => async (dispatch) => {
  dispatch({ type: "goals/load/pending" });
  const response = await getHook(
    dispatch,
    `/portfile/goals?briefcase_id=${id}`,
  );
  if (response?.detail) {
    dispatch({ type: "goals/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "goals/load/fulfilled", payload: response });
  }
};

export const uploadGoal = (data) => async (dispatch) => {
  const response = await postHook(dispatch, `/portfile/import_goal/`, data);
  dispatch(getGoals(data?.current_briefcase_id));
  if (!response.detail) {
    dispatch(addToast({ text: "Цель скопированa", type: "success" }));
  }
};

export const createGoal =
  ({ data, id }) =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: "goal/create/pending" });
    let formData = new FormData();
    formData.append("goal_type", data.goal_type);
    formData.append("smart_goal", data.smart_goal);
    formData.append("goal_currency", data.goal_currency);
    formData.append("goal_price", data.goal_price);
    formData.append("goal_currency_inflation", data.goal_currency_inflation);
    formData.append("percentage_inflation", data.percentage_inflation);
    formData.append("percentage_income", data.percentage_income);
    formData.append("risk_target_profile", data.risk_target_profile);
    formData.append("broker", data.broker);
    if (data.goal_type === "Пассивный доход") {
      formData.append(
        "amount_of_desired_income",
        data.amount_of_desired_income,
      );
    }
    formData.append("term", data.term);
    formData.append("month_year", data.month_year);
    formData.append(
      "amount_of_starting_capital_is_available",
      data.amount_of_starting_capital_is_available,
    );
    data.amount_of_starting_capital_is_available &&
      formData.append(
        "amount_of_starting_capital",
        data.amount_of_starting_capital || 0,
      );

    formData.append("replenishment_amount", data.replenishment_amount);
    formData.append("replenishment_frequency", data.replenishment_frequency);
    formData.append("recommended_amount", data.recommended_amount);
    formData.append("comment_for_expert", data.comment_for_expert || " ");
    formData.append("shariah", data.shariah);
    data?.brokers_list &&
      formData.append(
        "amount_of_starting_capital",
        data.amount_of_starting_capital || 0,
      );
    data?.brokers_list &&
      formData.append("brokers_list", JSON.stringify(data.brokers_list));
    formData.append("img", data.img);
    data.amount_of_tactical_strategy &&
      formData.append(
        "amount_of_tactical_strategy",
        data.amount_of_tactical_strategy || 0,
      );
    data.acceptable_risk &&
      formData.append("acceptable_risk", data.acceptable_risk);
    data.acceptable_risk_in_money &&
      formData.append(
        "acceptable_risk_in_money",
        data.acceptable_risk_in_money,
      );
    const response = await postHook(
      dispatch,
      `/portfile/goals?portfile_id=${id}`,
      formData,
    );
    if (response?.detail) {
      dispatch({
        type: "goal/create/rejected",
        error: response?.detail,
      });
    } else {
      const newGoal = [...state.goal?.data, response];
      dispatch({
        type: "goal/create/fulfilled",
        payload: { ...state.goal.data, goal: newGoal },
      });
      dispatch(getGoals(id));
      dispatch(addToast({ text: "Цель создана", type: "success" }));
    }
  };

export const fetchUpdateGoal =
  ({ data }) =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: "goal/update/pending" });
    let formData = new FormData();
    formData.append("goal_type", data.goal_type);
    formData.append("smart_goal", data.smart_goal);
    formData.append("goal_currency", data.goal_currency);
    formData.append("goal_price", data.goal_price);
    formData.append("goal_currency_inflation", data.goal_currency_inflation);
    formData.append("percentage_inflation", data.percentage_inflation);
    formData.append("percentage_income", data.percentage_income);
    formData.append("risk_target_profile", data.risk_target_profile);
    formData.append("broker", data.broker);
    if (data.goal_type === "Пассивный доход") {
      formData.append(
        "amount_of_desired_income",
        data.amount_of_desired_income,
      );
    }
    formData.append("term", data.term);
    formData.append("month_year", data.month_year);
    formData.append(
      "amount_of_starting_capital_is_available",
      data.amount_of_starting_capital_is_available,
    );
    data.amount_of_starting_capital_is_available &&
      formData.append(
        "amount_of_starting_capital",
        data.amount_of_starting_capital || 0,
      );
    formData.append("replenishment_amount", data.replenishment_amount);
    formData.append("replenishment_frequency", data.replenishment_frequency);
    formData.append("recommended_amount", data.recommended_amount);
    formData.append("comment_for_expert", data.comment_for_expert);
    formData.append("shariah", data.shariah);
    data?.brokers_list &&
      formData.append(
        "amount_of_starting_capital",
        data.amount_of_starting_capital || 0,
      );
    data?.brokers_list &&
      formData.append("brokers_list", JSON.stringify(data.brokers_list));
    data.amount_of_tactical_strategy &&
      formData.append(
        "amount_of_tactical_strategy",
        data.amount_of_tactical_strategy || 0,
      );
    data.acceptable_risk &&
      formData.append("acceptable_risk", data.acceptable_risk);
    data.acceptable_risk_in_money &&
      formData.append(
        "acceptable_risk_in_money",
        data.acceptable_risk_in_money,
      );
    if (typeof data.img !== "string") {
      formData.append("img", data.img);
    }
    const response = await putHook(
      dispatch,
      `/portfile/goals/${data.goalId}`,
      formData,
    );
    if (response?.detail) {
      dispatch({
        type: "goal/create/rejected",
        error: response?.detail,
      });
    } else {
      const newGoal = [
        ...state.goal.data.filter(({ id }) => id !== response.id),
        response,
      ];
      dispatch({
        type: "goal/update/fulfilled",
        payload: { ...state.goal.data, goal: newGoal },
      });
      dispatch(getGoals(data.id));
    }
  };

export const deleteGoal =
  ({ goalId, id, goalNumber }) =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: "goal/update/pending" });
    const response = await deleteHook(dispatch, `/portfile/goals/${goalId}`);

    if (response?.detail) {
      dispatch({ type: "goal/update/rejected", error: response?.detail });
    } else {
      const newGoal = [
        ...state.goal.data.filter(({ id }) => id !== response.id),
        response,
      ];
      dispatch({
        type: "goal/update/fulfilled",
        payload: { ...state.goal.data, goal: newGoal },
      });

      dispatch(getGoals(id));
      localStorage.setItem(
        `addNewGoalNumber_${id}`,
        goalNumber > 1 ? goalNumber - 1 : 1,
      );
    }
  };

export const fixTacticGoalStudent =
  (
    goal_id,
    acceptable_risk_expert,
    acceptable_risk_in_money_expert,
    amount_of_tactical_strategy_expert,
    id,
    user_id,
  ) =>
  async (dispatch) => {
    dispatch({ type: "goalStudent/update/pending" });
    const response = await putHook(
      dispatch,
      `/expert/student_goal?goal_id=${goal_id}`,
      {
        acceptable_risk: acceptable_risk_expert,
        acceptable_risk_in_money: acceptable_risk_in_money_expert,
        amount_of_tactical_strategy: amount_of_tactical_strategy_expert,
      },
    );
    if (response?.detail) {
      dispatch({
        type: "goalStudent/update/rejected",
        error: response?.detail[0]?.msg,
      });
    } else {
      dispatch({ type: "goalStudent/update/fulfilled", payload: response });
      dispatch(getPortfileStudent(id, user_id));
      dispatch(addToast({ text: "Цель откорректирована", type: "success" }));
    }
  };

export const fixGoalStudent =
  (
    goal_id,
    goal_type,
    percentage_inflation_expert,
    percentage_income_expert,
    goal_currency_expert,
    goal_currency_inflation_expert,
    goal_price_expert,
    risk_target_profile_expert,
    recommended_amount_expert,
    id,
    user_id,
  ) =>
  async (dispatch) => {
    dispatch({ type: "goalStudent/update/pending" });
    const response = await putHook(
      dispatch,
      `/expert/student_goal?goal_id=${goal_id}`,
      {
        goal_type,
        percentage_inflation: percentage_inflation_expert,
        percentage_income: percentage_income_expert,
        goal_currency: goal_currency_expert,
        goal_currency_inflation: goal_currency_inflation_expert,
        goal_price: goal_price_expert,
        risk_target_profile: risk_target_profile_expert,
        recommended_amount: recommended_amount_expert,
      },
    );
    if (response?.detail) {
      dispatch({
        type: "goalStudent/update/rejected",
        error: response?.detail[0]?.msg,
      });
    } else {
      dispatch({ type: "goalStudent/update/fulfilled", payload: response });
      dispatch(getPortfileStudent(id, user_id));
      dispatch(addToast({ text: "Цель откорректирована", type: "success" }));
    }
  };
export const fixMethodGoalStudent =
  (
    goal_id,
    acceptable_risk_expert,
    acceptable_risk_in_money_expert,
    amount_of_tactical_strategy_expert,
    id,
    user_id,
  ) =>
  async (dispatch) => {
    dispatch({ type: "goalStudent/update/pending" });
    const response = await putHook(
      dispatch,
      `/expert/student_goal?goal_id=${goal_id}`,
      {
        acceptable_risk_expert,
        acceptable_risk_in_money_expert,
        amount_of_tactical_strategy_expert,
      },
    );
    if (response?.detail) {
      dispatch({
        type: "goalStudent/update/rejected",
        error: response?.detail[0]?.msg,
      });
    } else {
      dispatch({ type: "goalStudent/update/fulfilled", payload: response });
      dispatch(getPortfileStudent(id, user_id));
      dispatch(addToast({ text: "Цель откорректирована", type: "success" }));
    }
  };

export const joinGoals = (portfile_id, goals_id) => async (dispatch) => {
  dispatch({ type: "goals/join/pending" });
  const response = await putHook(dispatch, `/expert/join_goal`, {
    portfile_id,
    goals_id,
  });
  if (response?.detail) {
    dispatch({ type: "goals/join/rejected", error: response?.detail });
  } else {
    dispatch({ type: "goals/join/fulfilled", payload: response });
    window.location.reload();
  }
};

export const unMergeGoals = (portfile_id) => async (dispatch) => {
  dispatch({ type: "goals/join/pending" });
  const response = await deleteHook(dispatch, `/expert/join_goal`, {
    portfile_id,
  });
  if (response?.detail) {
    dispatch({ type: "goals/join/rejected", error: response?.detail });
  } else {
    dispatch({ type: "goals/join/fulfilled", payload: response });
    window.location.reload();
  }
};

export const getCourse = (id) => async (dispatch) => {
  dispatch({ type: "course/load/pending" });
  const response = await getHook(dispatch, `/courses/course?id=${id}`);
  if (response?.detail) {
    dispatch({ type: "course/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "course/load/fulfilled", payload: response });
  }
};

export const savePortfolioElapsedTime = (data) => async (dispatch) => {
  const response = await postHook(dispatch, `/expert/elapsed_time`, data);
  if (response?.detail) {
  } else {
    dispatch(addToast({ text: "Время проверки сохраненo", type: "success" }));
  }
  return response;
};
export default goal;
