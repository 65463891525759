const initialState = {
  loading: false,
};

const images = (state = initialState, action) => {
  switch (action.type) {
    case "images/load/pending":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export const uploadImageLoading = (data) => (dispatch) => {
  dispatch({ type: "images/load/pending", payload: data });
};

export default images;
