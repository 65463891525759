import React from "react";
import styles from "./loader.module.sass";

function Loader({ size }) {
  return (
    <div
      className={styles.Loader}
      style={size && { width: size, height: size }}
    >
      <div className={styles.loader_container}></div>
    </div>
  );
}

export default Loader;
