import { getFinPlanStudent, getPortfolioStudent } from "./portfolio";
import {
  addCommentAssetsGeneral,
  addMethodCommentAssetsGeneral,
  editCommentAssetsGeneral,
  editMethodCommentAssetsGeneral,
  getCommentAssetsByTicker,
} from "./comments";
import { addToast } from "../fetchWrapper/toast";
import {
  deleteHook,
  getHook,
  postHook,
  putHook,
} from "../fetchWrapper/useAxios";

const initialState = {
  data: [],
  error: null,
  loading: false,
  success: false,
  count: null,
};

const expertAssets = (state = initialState, action) => {
  switch (action.type) {
    case "assets/create/pending":
      return { ...state, loading: true, error: null, success: null };
    case "assets/create/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "assets/create/rejected":
      return { ...state, error: action.error };

    case "assets/update/pending":
      return { ...state, loading: true, error: null };
    case "assets/update/fulfilled":
      return { ...state, loading: false, success: true };
    case "assets/update/rejected":
      return { ...state, error: action.error };

    case "assets/delete/pending":
      return { ...state, loading: true, error: null };
    case "assets/delete/fulfilled":
      return { ...state, loading: false, success: true };
    case "assets/delete/rejected":
      return { ...state, error: action.error };

    case "assets/check/pending":
      return { ...state, loading: true, error: null };
    case "assets/check/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "assets/check/rejected":
      return { ...state, error: action.error };

    case "assets/percent/pending":
      return { ...state, loading: true, error: null };
    case "assets/percent/fulfilled":
      return { ...state, loading: false, count: action.payload, success: true };
    case "assets/percent/rejected":
      return { ...state, error: action.error };

    case "chartsExpert/get/pending":
      return { ...state, loading: true, error: null, success: null };
    case "chartsExpert/get/fulfilled":
      return { ...state, loading: false, data: action.payload, success: true };
    case "chartsExpert/get/rejected":
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export const updateExpertAsset = (
  goal_id,
  asset_id,
  asset_type,
  ticker,
  name,
  price,
  current_price,
  count,
  purchase_currency,
  currency_asset,
  percentage_in_portfolio,
  type_inctrument,
  asset_part,
  country,
  sector_economy,
  comment,
  portfile_id,
  user_id,
  lot_size,
  matdate,
  instrument_id,
) => {
  return async (dispatch) => {
    dispatch({ type: "assets/update/pending" });
    const item = await dispatch(
      getCommentAssetsByTicker({
        asset_part: asset_part,
        ticker: ticker,
      }),
    );
    const response = await putHook(
      dispatch,
      `/expert/expert_assets?portfile_id=${portfile_id}&asset_id=${asset_id}&goal_id=${goal_id}`,
      {
        asset_type,
        ticker,
        name,
        price,
        current_price,
        count,
        purchase_currency,
        currency_asset,
        percentage_in_portfolio,
        type_inctrument,
        asset_part,
        country,
        sector_economy,
        comment,
        lot_size,
        matdate,
        instrument_id,
      },
    );
    if (comment) {
      let id = item?.id;
      if (id) {
        dispatch(
          editCommentAssetsGeneral(
            asset_part,
            type_inctrument,
            ticker,
            comment,
            id,
          ),
        );
      } else {
        dispatch(
          addCommentAssetsGeneral(asset_part, type_inctrument, ticker, comment),
        );
      }
    }
    if (response?.detail) {
      dispatch({ type: "assets/update/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/update/fulfilled", payload: response });
      dispatch(getFinPlanStudent(goal_id));
      dispatch(
        addToast({
          text: "Актив скорректирован",
          type: "success",
        }),
      );
    }
  };
};

export const createExpertAsset = (
  goal_id,
  asset_type,
  ticker,
  name,
  price,
  current_price,
  count,
  purchase_currency,
  currency_asset,
  percentage_in_portfolio,
  type_inctrument,
  asset_part,
  country,
  sector_economy,
  comment,
  portfile_id,
  user_id,
  lot_size,
  matdate,
  instrument_id,
) => {
  return async (dispatch) => {
    dispatch({ type: "assets/create/pending" });
    const item = await dispatch(
      getCommentAssetsByTicker({
        asset_part: asset_part,
        ticker: ticker,
      }),
    );
    const response = await postHook(
      dispatch,
      `/expert/expert_assets?portfile_id=${portfile_id}&goal_id=${goal_id}`,
      {
        asset_type,
        ticker,
        name,
        price,
        current_price,
        count,
        purchase_currency,
        currency_asset,
        percentage_in_portfolio,
        type_inctrument,
        asset_part,
        country,
        sector_economy,
        comment,
        lot_size,
        matdate,
        instrument_id,
      },
    );

    if (comment) {
      let id = item?.id;
      if (id) {
        dispatch(
          editCommentAssetsGeneral(
            asset_part,
            type_inctrument,
            ticker,
            comment,
            id,
          ),
        );
      } else {
        dispatch(
          addCommentAssetsGeneral(asset_part, type_inctrument, ticker, comment),
        );
      }
    }
    if (response?.detail) {
      dispatch({ type: "assets/create/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/create/fulfilled", payload: response });
      dispatch(getFinPlanStudent(goal_id));
      dispatch(
        addToast({
          text: "Актив добавлен",
          type: "success",
        }),
      );
    }
    return response;
  };
};

export const createMethodExpertAsset = (goal_id, portfile_id, data) => {
  return async (dispatch) => {
    dispatch({ type: "assets/create/pending" });
    const item = await dispatch(
      getCommentAssetsByTicker({
        asset_part: data.asset_part,
        ticker: data.ticker,
        type_inctrument: data.type_inctrument,
      }),
    );
    const response = await postHook(
      dispatch,
      `/expert/expert_assets?portfile_id=${portfile_id}&goal_id=${goal_id}`,
      data,
    );

    if (data.comment) {
      let id = item?.id;
      if (item?.id) {
        dispatch(
          editCommentAssetsGeneral(
            data.asset_part,
            data.ticker,
            data.comment,
            id,
          ),
        );
      } else {
        dispatch(
          addCommentAssetsGeneral(data.asset_part, data.ticker, data.comment),
        );
      }
    }
    if (response?.detail) {
      dispatch({ type: "assets/create/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/create/fulfilled", payload: response });
      dispatch(getFinPlanStudent(goal_id));
      dispatch(
        addToast({
          text: "Актив добавлен",
          type: "success",
        }),
      );
    }
    return response;
  };
};

export const updateMethodExpertAsset = (
  goal_id,
  asset_id,
  portfile_id,
  data,
) => {
  return async (dispatch) => {
    dispatch({ type: "assets/update/pending" });
    const response = await putHook(
      dispatch,
      `/expert/expert_assets?portfile_id=${portfile_id}&asset_id=${asset_id}&goal_id=${goal_id}`,
      data,
    );
    if (response?.detail) {
      dispatch({ type: "assets/update/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/update/fulfilled", payload: response });
      dispatch(getFinPlanStudent(goal_id));
      dispatch(
        addToast({
          text: "Актив скорректирован",
          type: "success",
        }),
      );
    }
  };
};

export const deleteExpertAsset =
  (goalId, assetId, portfile_id, user_id) => async (dispatch) => {
    dispatch({ type: "assets/delete/pending" });
    const response = await deleteHook(
      dispatch,
      `/expert/expert_assets?portfile_id=${portfile_id}&asset_id=${assetId}&goal_id=${goalId}`,
    );
    if (response?.status_code !== 200) {
      dispatch({ type: "assets/delete/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/delete/fulfilled", payload: assetId });
      dispatch(getFinPlanStudent(goalId));
      dispatch(addToast({ text: "Актив удален", type: "success" }));
    }
  };

export const getChartsDataExpert =
  (goalId, portfileId, expert, active) => async (dispatch, getState) => {
    dispatch({ type: "chartsExpert/get/pending" });
    const response = await getHook(
      dispatch,
      `/chart/chart_data?goal_id=${goalId}&briefcase_id=${portfileId}&active=${active}&expert=${expert}`,
    );
    if (response?.detail) {
      dispatch({ type: "chartsExpert/get/rejected", error: response?.detail });
    } else {
      dispatch({ type: "chartsExpert/get/fulfilled", payload: response });
    }
  };

export const checkAsset =
  (goal_id, asset_id, check, portfile_id, user_id) => async (dispatch) => {
    dispatch({ type: "assets/check/pending" });
    const response = await postHook(
      dispatch,
      `/expert/student_asset/verified?goal_id=${goal_id}&asset_id=${asset_id}&check=${check}`,
    );
    if (response?.detail) {
      dispatch({ type: "assets/check/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/check/fulfilled", payload: response });
      dispatch(getFinPlanStudent(goal_id));
    }
  };

export const fixAssetStudent = (
  portfile_id,
  goal_id,
  asset_id,
  asset_part,
  ticker_expert,
  percentage_in_portfile_expert,
  current_price_expert,
  count_expert,
  purchase_currency_expert,
  currency_asset_expert,
  type_inctrument_expert,
  country_expert,
  sector_economy_expert,
  comment_expert,
  additional_comment,
  check,
  ticker,
  user_id,
) => {
  return async (dispatch, getState) => {
    dispatch({ type: "assets/update/pending" });
    const response = await putHook(
      dispatch,
      `/expert/student_asset?goal_id=${goal_id}&asset_id=${asset_id}`,
      {
        asset_part,
        ticker: ticker_expert,
        percentage_in_portfile: percentage_in_portfile_expert,
        current_price: current_price_expert,
        count: count_expert,
        purchase_currency: purchase_currency_expert,
        currency_asset: currency_asset_expert,
        type_inctrument: type_inctrument_expert,
        country: country_expert,
        sector_economy: sector_economy_expert,
        comment: comment_expert ? comment_expert?.trim() : -1,
        additional_comment: additional_comment
          ? additional_comment?.trim()
          : -1,
        check,
      },
    );

    let comment = comment_expert;
    let type_inctrument = type_inctrument_expert;
    if (comment) {
      const item = await dispatch(
        getCommentAssetsByTicker({
          asset_part: asset_part,
          ticker: ticker,
        }),
      );
      let id = item?.id;
      if (item?.id) {
        dispatch(
          editCommentAssetsGeneral(
            asset_part,
            type_inctrument,
            ticker,
            comment,
            id,
          ),
        );
      } else {
        dispatch(
          addCommentAssetsGeneral(asset_part, type_inctrument, ticker, comment),
        );
      }
    }
    if (response?.detail) {
      dispatch({ type: "assets/update/rejected", error: response?.detail });
      return false;
    } else {
      dispatch({ type: "assets/update/fulfilled", payload: response });
      dispatch(getFinPlanStudent(goal_id));
      return true;
    }
  };
};
export const fixMethodAssetStudent = (goal_id, asset_id, data, asset) => {
  return async (dispatch, getState) => {
    dispatch({ type: "assets/update/pending" });
    const response = await putHook(
      dispatch,
      `/expert/student_asset?goal_id=${goal_id}&asset_id=${asset_id}`,
      data,
    );
    let comment =
      asset?.type_inctrument === "Акции"
        ? data.additional?.fundamental_analysis
        : data?.comment;
    let comment2 =
      asset?.type_inctrument === "Акции"
        ? data.additional?.technical_analysis
        : "";
    let strategy_id =
      asset?.type_inctrument !== "Недвижимость"
        ? 10
        : asset.additional?.estate_type === "Сдача в аренду"
        ? 20
        : 30;
    if (comment) {
      const item = await dispatch(
        getCommentAssetsByTicker({
          asset_part: asset.asset_step,
          ticker: asset.ticker,
          type_inctrument: asset.type_inctrument,
        }),
      );

      let id = item?.id;
      if (item?.id) {
        dispatch(
          editMethodCommentAssetsGeneral({
            asset_part: asset.asset_step,
            tiker_name: asset.ticker,
            comment: comment,
            comment2: comment2,
            type_inctrument: asset.type_inctrument,
            additional: { images: data?.additional?.images },
            strategy_id: strategy_id,
            id: id,
          }),
        );
      } else {
        dispatch(
          addMethodCommentAssetsGeneral({
            asset_part: asset.asset_step,
            tiker_name: asset.ticker,
            comment: comment,
            comment2: comment2,
            type_inctrument: asset.type_inctrument,
            additional: { images: data.additional?.images },
            strategy_id: strategy_id,
          }),
        );
      }
    }
    if (response?.detail) {
      dispatch({ type: "assets/update/rejected", error: response?.detail });
      return false;
    } else {
      dispatch({ type: "assets/update/fulfilled", payload: response });
      dispatch(getFinPlanStudent(goal_id));
      return true;
    }
  };
};
export const updateMethodAssetStudent = (goal_id, asset_id, data) => {
  return async (dispatch) => {
    dispatch({ type: "assets/update/pending" });
    const response = await putHook(
      dispatch,
      `/expert/student_asset?goal_id=${goal_id}&asset_id=${asset_id}`,
      data,
    );
    if (response?.detail) {
      dispatch({ type: "assets/update/rejected", error: response?.detail });
      return false;
    } else {
      dispatch({ type: "assets/update/fulfilled", payload: response });
      dispatch(addToast({ text: "Актив обновлен", type: "success" }));
      dispatch(getFinPlanStudent(goal_id));
      return true;
    }
  };
};
export const getAssetPercentPortfile =
  ({
    goalId,
    value,
    current_price,
    purchase_currency,
    portfileId,
    name,
    user_id,
    lot_size,
  }) =>
  async (dispatch) => {
    dispatch({ type: "assets/percent/pending" });
    const response = await getHook(
      dispatch,
      `/expert/student_asset_count?goal_id=${goalId}&current_price=${current_price}&purchase_currency=${purchase_currency}&lot_size=${lot_size}${
        name === "count" ? `&count=${value}` : `&percent=${value}`
      }`,
    );

    if (response?.detail) {
      dispatch({ type: "assets/percent/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/percent/fulfilled", payload: response });
    }
    return response;
  };
export const copyAssetForExpert = (asset_id, portfile_id, user_id, goal_id) => {
  return async (dispatch, getState) => {
    dispatch({ type: "assets/create/pending" });
    const response = await postHook(
      dispatch,
      `/expert/copy_expert_assets?asset_id=${asset_id}`,
    );

    if (response?.detail) {
      dispatch({ type: "assets/create/rejected", error: response?.detail });
    } else {
      dispatch({ type: "assets/create/fulfilled", payload: response });
      dispatch(getFinPlanStudent(goal_id));
      dispatch(
        addToast({ text: "Актив скопирован в портфель", type: "success" }),
      );
    }
  };
};
export const copyAllStudentAssets = (goal_id, asset_step) => {
  return async (dispatch) => {
    await postHook(
      dispatch,
      `/expert/copy_student_all_assets?goal_id=${goal_id}&asset_step=${asset_step}`,
    );
    dispatch(getFinPlanStudent(goal_id));
    dispatch(addToast({ text: "Активы скопированы", type: "success" }));
  };
};
export const updateAssetsPrice = (goal_id) => {
  return async (dispatch) => {
    const res = await getHook(
      dispatch,
      `/expert/update_price?goal_id=${goal_id}`,
    );
    if (!res?.details) {
      dispatch(getFinPlanStudent(goal_id));
      dispatch(addToast({ text: "Цены актуализированы", type: "success" }));
    }
    return res;
  };
};
export const resetAssetsData = (goal_id, briefcase_id, user_id) => {
  return async (dispatch) => {
    const res = await deleteHook(
      dispatch,
      `/expert/rebalancing?goal_id=${goal_id}`,
    );
    dispatch(getFinPlanStudent(goal_id));
    dispatch(getPortfolioStudent(briefcase_id, user_id));
    dispatch(
      addToast({
        text: "Сбросили все настройки портфеля по Марковица",
        type: "success",
      }),
    );
    return res;
  };
};

export default expertAssets;
