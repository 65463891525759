import {
  deleteHook,
  getHook,
  postHook,
  putHook,
} from "../fetchWrapper/useAxios";
import { getPortfolioStudent } from "./portfolio";

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const experts = (state = initialState, action) => {
  switch (action.type) {
    case "expert/add/pending":
      return { ...state, isLoading: true, error: null };
    case "expert/add/fulfilled":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };
    case "expert/add/rejected":
      return { ...state, isLoading: false, error: action.error };

    case "expert/get/pending":
      return { ...state, isLoading: true, error: null };
    case "expert/get/fulfilled":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };
    case "expert/get/rejected":
      return { ...state, isLoading: false, error: action.error };

    case "expert/core/pending":
      return { ...state, isLoading: true, error: null };
    case "expert/core/fulfilled":
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case "expert/core/rejected":
      return { ...state, isLoading: false, error: action.error };

    case "expert/pending":
      return { ...state, isLoading: true, error: null };
    case "expert/fulfilled":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      };
    case "expert/rejected":
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

export const addExpert = (data) => async (dispatch) => {
  dispatch({ type: "expert/add/pending" });
  let formData = new FormData();
  formData.append("avatar", data.avatar);
  formData.append("fullname", data.fullname);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("rights", data.rights);
  formData.append("password", data.password);
  const response = await postHook(dispatch, "/expert/add_expert", formData);
  if (response?.detail) {
    dispatch({ type: "expert/add/rejected", error: response?.detail });
  } else {
    dispatch({ type: "expert/add/fulfilled", payload: response });
    dispatch(getDataExpert());
  }
};

export const editExpert = (id, data) => async (dispatch) => {
  dispatch({ type: "expert/pending" });
  let formData = new FormData();
  formData.append("avatar", data.avatar);
  formData.append("fullname", data.fullname);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("rights", data.rights);
  formData.append("password", data.password);
  const response = await putHook(
    dispatch,
    `/expert/update_experts?id=${id}`,
    formData
  );
  if (response?.detail) {
    dispatch({ type: "expert/rejected", error: response?.detail });
  } else {
    dispatch({ type: "expert/fulfilled", payload: response });
    dispatch(getDataExpert());
  }
};

export const deleteExpert = (id, newExpert) => async (dispatch) => {
  dispatch({ type: "expert/pending" });
  const response = await deleteHook(
    dispatch,
    `/expert/experts?old_expert_id=${id}&new_expert_id=${newExpert}`
  );

  if (response?.detail) {
    dispatch({ type: "expert/rejected", error: response?.detail });
  } else {
    dispatch({ type: "expert/fulfilled", payload: response });
    dispatch(getDataExpert());
  }
};

export const getDataExpert = (params) => async (dispatch) => {
  dispatch({ type: "expert/get/pending" });
  const response = await getHook(
    dispatch,
    `/expert/experts/list?${new URLSearchParams(params)}`
  );
  if (response?.detail) {
    dispatch({ type: "expert/get/rejected", error: response?.detail });
  } else {
    dispatch({ type: "expert/get/fulfilled", payload: response });
  }
};

export const editActiveExpert = (id, isActive) => async (dispatch) => {
  dispatch({ type: "expert/get/pending" });
  const response = await putHook(
    dispatch,
    `/expert/experts/active?expert_id=${id}&active=${isActive}`
  );
  if (response?.detail) {
    dispatch({ type: "expert/get/rejected", error: response?.detail });
  } else {
    dispatch({ type: "expert/get/fulfilled", payload: response });
    dispatch(getDataExpert());
  }
};
export const expertCoreAndTactic = (data) => async (dispatch) => {
  dispatch({ type: "expert/core/pending" });
  const response = await postHook(
    dispatch,
    "/expert/expert_core_tactics_share",
    {
      goal_id: data.goalId,
      core_share: data.coreSum,
      core_share_percent: data.corePercent,
      tactics_share: data.tacticSum,
      tactics_share_percent: data.tacticPercent,
    }
  );
  if (response?.detail) {
    dispatch({ type: "expert/core/rejected", error: response?.detail });
  } else {
    dispatch({ type: "expert/core/fulfilled", payload: response });
    dispatch(getPortfolioStudent(data?.portfileId, data?.user_id));
  }
};

export default experts;
