import { getHook } from "../fetchWrapper/useAxios";

const initialState = {
  data: [],
  course: {},
  error: null,
  loading: false,
};

const courses = (state = initialState, action) => {
  switch (action.type) {
    case "courses/load/pending":
      return { ...state, loading: true, error: null, success: null };
    case "courses/load/fulfilled":
      return { ...state, loading: false, data: action.payload };
    case "courses/load/rejected":
      return { ...state, error: action.error };
    case "course/load/pending":
      return { ...state, loading: true, error: null, success: null };
    case "course/load/fulfilled":
      return { ...state, loading: false, course: action.payload };
    case "course/load/rejected":
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export const getAllCourses = () => async (dispatch) => {
  dispatch({ type: "courses/load/pending" });
  const response = await getHook(dispatch, "/courses/all");
  if (response?.detail) {
    dispatch({ type: "courses/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "courses/load/fulfilled", payload: response });
  }
};
export const getCourse = (id) => async (dispatch) => {
  dispatch({ type: "course/load/pending" });
  const response = await getHook(dispatch, `/courses/course?id=${id}`);
  if (response?.detail) {
    dispatch({ type: "course/load/rejected", error: response?.detail });
  } else {
    dispatch({ type: "course/load/fulfilled", payload: response });
  }
};

export default courses;
